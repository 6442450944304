<template>
  <div class="header--profile">
    <!-- User's info -->
    <div class="header--profile--head">
      <Avatar class="h-[24px]" />
      <p class="header--profile--head__title">
        {{ user?.username || user?.email }}
      </p>
    </div>
    <!-- Menu buttons -->
    <div class="header--profile--menu">
      <router-link to="/account">
        <button class="header--profile--menu__button">
          <Icon :name="'person'" />
          <p>Управление аккаунтом</p>
        </button>
      </router-link>
      <button class="header--profile--menu__button" @click="logout">
        <Icon :name="'logout'" />
        <p>Выйти</p>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Icon } from "ui";
import Avatar from "@/components/Avatar.vue";

export default {
  components: { Icon, Avatar },
  data() {
    return {
      showProfile: false,
    };
  },
  computed: {
    ...mapGetters({ user: "auth/user" }),
  },
  methods: {
    ...mapActions({ logout: "auth/logout" }),
  },
};
</script>

<style scoped lang="scss">
.header--profile {
  @apply w-[210px] bg-white rounded-[5px] z-[10];
  filter: drop-shadow(0px 0px 35px rgba(109, 120, 150, 0.15));

  &--head {
    @apply flex items-center gap-[12px] w-full p-[10px] border-b-[1px] border-solid border-back;

    &__title {
      @apply text-button truncate;
    }
  }

  &--menu {
    @apply flex flex-col gap-[4px] w-full p-[4px];

    &__button {
      @apply flex items-center justify-start gap-[14px] px-[12px] py-[6px] bg-transparent rounded-[5px] w-full fill-color-text-main stroke-color-text-main text-button hover:bg-zebra active:bg-inactive;

      & svg {
        @apply h-[16px] aspect-square fill-color-text-main stroke-color-text-main;
      }
    }
  }
}
</style>
