<template>
  <div class="input--lg">
    <div>
      <p
        class="input--lg--text"
      >
        {{ value !== null ? value.name : "Файл не выбран" }}
      </p>

      <!-- Placeholder -->
      <span class="floating-label static">
        {{ placeholder }}
      </span>

      <input
        id="file-upload"
        style="display: none"
        type="file"
        required
        @input="choosedFile($event)"
      />
      <div class="dark">
        <ButtonSm>
          <p>Выбрать файл</p>
          <label for="file-upload" class="absolute inset-0 cursor-pointer" />
        </ButtonSm>
      </div>
      <slot />
    </div>

    <!-- Warning text -->
    <p v-if="wrong" class="text-sm text-status-red mt-[5px]">{{ warningText }}</p>
  </div>
</template>

<script>
import ButtonSm from "../button/sm.vue";
import Icon from "../Icon.vue";

export default {
  components: { ButtonSm, Icon },
  props: {
    value: String,
    warningText: {
      default: "Неверные данные",
      type: String,
    },
    placeholder: {
      default: "Введите",
      type: String,
    },
  },
  emits: ["update:value"],
  data() {
    return {
      wrong: false,
    };
  },
  computed: {
    content() {
      return this.value;
    },
  },
  methods: {
    changeStatus(state) {
      this.wrong = state;
    },
    choosedFile(data) {
      this.$emit("update:value", data.target.files[0]);
    },
  },
};
</script>

<style scoped lang="scss"></style>
