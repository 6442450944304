const state = () => ({
  pageSize: 50,
  pagesCount: 1,
  page: 1,
  origin: 0,
})

const mutations = {
  SET_PAGES_COUNT(state, payload) {
    state.pagesCount = payload
  },
  SET_PAGE_SIZE(state, payload) {
    state.pageSize = payload
    state.page = 1
  },
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_ORIGIN(state, payload) {
    state.origin = payload
  },
}

const actions = {
}

const getters = {
  pageSize: (state) => state.pageSize,
  pagesCount: (state) => state.pagesCount,
  page: (state) => state.page,
  origin: (state) => state.origin,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}