<template>
  <!-- Color icons -->
  <svg
    v-if="name === 'clients'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.90066 16.3812C1.97455 17.0967 2.44367 17.6631 3.14723 17.8129C4.25775 18.0493 6.35117 18.3333 9.99984 18.3333C13.6485 18.3333 15.7419 18.0493 16.8525 17.8129C17.556 17.6631 18.0251 17.0967 18.099 16.3812C18.2086 15.3203 18.3332 13.3724 18.3332 9.99996C18.3332 6.6275 18.2086 4.67963 18.099 3.61872C18.0251 2.9032 17.556 2.33683 16.8524 2.18705C15.7419 1.95063 13.6485 1.66663 9.99984 1.66663C6.35117 1.66663 4.25775 1.95063 3.14723 2.18705C2.44367 2.33683 1.97455 2.9032 1.90066 3.61872C1.7911 4.67963 1.6665 6.6275 1.6665 9.99996C1.6665 13.3724 1.7911 15.3203 1.90066 16.3812Z"
      fill="#C0C0C0"
    />
    <path
      d="M11.615 11.5465L8.38414 11.5466C6.90559 12.1099 5.77664 13.4243 5.42693 15.0441C5.37855 15.2682 5.50189 15.4983 5.72768 15.5392C6.53801 15.6858 7.87997 15.8333 10.0003 15.8333C12.1207 15.8333 13.4626 15.6858 14.273 15.5392C14.4979 15.4985 14.621 15.2693 14.5728 15.0459C14.2236 13.4252 13.0942 12.11 11.615 11.5465Z"
      fill="#F02425"
    />
    <ellipse cx="10.0002" cy="8.50118" rx="3.28" ry="3.28231" fill="white" />
  </svg>

  <svg
    v-else-if="name === 'dashboard'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4488 16.2353C17.0787 16.2353 17.6739 15.9048 17.9243 15.3267C18.3893 14.2529 18.647 13.0683 18.647 11.8235C18.647 6.95042 14.6966 3 9.82352 3C4.95042 3 1 6.95042 1 11.8235C1 13.0683 1.25775 14.2529 1.7228 15.3267C1.97313 15.9048 2.56836 16.2353 3.1983 16.2353H16.4488Z"
      fill="#C0C0C0"
    />
    <path
      d="M2.41211 13.1029C2.41211 12.4499 2.87003 12 3.51505 12C4.16007 12 4.61799 12.4499 4.61799 13.1029C4.61799 13.8042 4.10855 14.2097 3.44945 14.2057C2.81693 14.201 2.41211 13.7355 2.41211 13.1029Z"
      fill="white"
    />
    <path
      d="M4.56982 9.97542C4.56982 9.97542 4.82754 9.19238 5.54007 8.24871C6.25259 7.30503 6.93264 6.98621 6.93264 6.98621"
      stroke="white"
      stroke-width="2.2"
      stroke-linecap="round"
    />
    <path
      d="M12.2157 8.52805C12.2564 7.82406 11.3939 7.58891 11.0833 8.21931C10.4572 9.4899 9.69085 11.1139 9.01571 12.742C8.18855 13.0128 7.50923 13.692 7.2676 14.6096C6.88929 16.0461 7.72713 17.5226 9.13889 17.9075C10.5507 18.2925 12.0018 17.44 12.38 16.0035C12.6217 15.0859 12.3672 14.152 11.7838 13.4967C11.9992 11.7433 12.1336 9.94696 12.2157 8.52805Z"
      fill="#F02425"
    />
  </svg>

  <svg
    v-else-if="name === 'dashboard-up'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_855_5642)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7812 5.90625C13.7812 5.54381 14.0751 5.25 14.4375 5.25H19.0312C19.3937 5.25 19.6875 5.54381 19.6875 5.90625V10.5C19.6875 10.8624 19.3937 11.1562 19.0312 11.1562C18.6688 11.1562 18.375 10.8624 18.375 10.5V7.49058L13.2048 12.6608C13.2048 12.6608 13.2047 12.6608 13.2047 12.6609C13.0219 12.8437 12.8049 12.9887 12.566 13.0877C12.3271 13.1867 12.0711 13.2376 11.8125 13.2376C11.5539 13.2376 11.2979 13.1867 11.059 13.0877C10.8201 12.9887 10.603 12.8437 10.4202 12.6608L8.33915 10.5797L8.33909 10.5797C8.27815 10.5187 8.2058 10.4704 8.12617 10.4374C8.04654 10.4044 7.96119 10.3874 7.875 10.3874C7.78881 10.3874 7.70346 10.4044 7.62383 10.4374C7.5442 10.4704 7.47185 10.5187 7.41091 10.5797L7.41085 10.5797L2.43279 15.5578C2.17651 15.8141 1.76099 15.8141 1.50471 15.5578C1.24843 15.3015 1.24843 14.886 1.50471 14.6297L6.48272 9.6517C6.66555 9.46883 6.8826 9.32378 7.12149 9.22481C7.36038 9.12584 7.61642 9.07491 7.875 9.07491C8.13358 9.07491 8.38962 9.12584 8.62851 9.22481C8.8674 9.32378 9.08445 9.46883 9.26728 9.6517L11.3484 11.7328L11.3484 11.7328C11.4093 11.7938 11.4817 11.8421 11.5613 11.8751C11.641 11.9081 11.7263 11.9251 11.8125 11.9251C11.8987 11.9251 11.984 11.9081 12.0637 11.8751C12.1433 11.8421 12.2157 11.7938 12.2766 11.7328L12.2766 11.7328L17.4469 6.5625H14.4375C14.0751 6.5625 13.7812 6.26869 13.7812 5.90625Z"
        stroke="transparent"
      />
    </g>
    <defs>
      <clipPath id="clip0_855_5642">
        <rect width="21" height="21" fill="transparent" stroke="transparent" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'download'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6665 10.3333V11.6666C2.6665 12.0202 2.80698 12.3593 3.05703 12.6094C3.30708 12.8594 3.64622 12.9999 3.99984 12.9999H11.9998C12.3535 12.9999 12.6926 12.8594 12.9426 12.6094C13.1927 12.3593 13.3332 12.0202 13.3332 11.6666V10.3333"
      stroke-width="1.3"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M4.6665 6.33325L7.99984 9.66659L11.3332 6.33325"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 1.66675V9.66675"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'games'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.21629 7.10375C6.41929 7.22646 6.68346 7.15975 6.80179 6.95675C7.42825 5.88192 8.52246 4.00459 9.25167 2.75352C9.57825 2.19316 10.4021 2.19316 10.7287 2.75351C11.4579 4.00459 12.5521 5.88192 13.1786 6.95675C13.2969 7.15975 13.5611 7.22646 13.7641 7.10375C14.8388 6.45413 16.063 5.64675 16.9972 5.01679C17.5936 4.61459 18.401 5.06771 18.3286 5.77384C18.0879 8.12117 17.5973 12.2745 16.9005 15.0707C16.8025 15.4644 16.5705 15.8025 16.1778 15.9294C15.4213 16.1738 13.7141 16.4999 9.99017 16.4999C6.35684 16.4999 4.64329 16.1895 3.85946 15.9474C3.4279 15.8141 3.18298 15.4393 3.08919 15.0053C2.40654 11.8465 1.92044 8.00746 1.67196 5.79621C1.59226 5.08704 2.40308 4.62575 3.00276 5.03C3.93443 5.65804 5.14904 6.45867 6.21629 7.10375Z"
      fill="#C0C0C0"
    />
    <path
      d="M12.5 11.0833C12.5 11.7083 10.625 14.4167 10 14.4167C9.375 14.4167 7.5 11.7083 7.5 11.0833C7.5 10.4583 9.375 7.75 10 7.75C10.625 7.75 12.5 10.4583 12.5 11.0833Z"
      fill="#F02425"
    />
    <path
      d="M14.5 10C15.3602 10 15.9934 10.5506 15.9997 11.4108C15.9999 11.4399 16 11.4697 16 11.5C16 11.5303 15.9999 11.5601 15.9997 11.5892C15.9934 12.4494 15.3602 13 14.5 13C13.6398 13 13.0066 12.4494 13.0003 11.5892C13.0001 11.5601 13 11.5303 13 11.5C13 11.4697 13.0001 11.4399 13.0003 11.4108C13.0066 10.5506 13.6398 10 14.5 10Z"
      fill="white"
    />
    <path
      d="M5.5 10C6.36025 10 6.9934 10.5506 6.9997 11.4108C6.99992 11.4399 7 11.4697 7 11.5C7 11.5303 6.99992 11.5601 6.9997 11.5892C6.9934 12.4494 6.36025 13 5.5 13C4.63975 13 4.0066 12.4494 4.0003 11.5892C4.00008 11.5601 4 11.5303 4 11.5C4 11.4697 4.00008 11.4399 4.0003 11.4108C4.0066 10.5506 4.63975 10 5.5 10Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'logs'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6262 3.12116C16.3453 3.19333 16.9092 3.6693 17.0503 4.37808C17.259 5.42721 17.5 7.34792 17.5 10.625C17.5 13.902 17.259 15.8227 17.0503 16.8718C16.9092 17.5806 16.3453 18.0566 15.6262 18.1288C14.6478 18.227 12.9153 18.3333 10 18.3333C7.08467 18.3333 5.35221 18.227 4.37379 18.1288C3.65472 18.0566 3.09079 17.5806 2.94975 16.8718C2.74098 15.8227 2.5 13.902 2.5 10.625C2.5 7.34792 2.74098 5.42721 2.94975 4.37808C3.09079 3.6693 3.65472 3.19333 4.37379 3.12116C5.35221 3.02296 7.08467 2.91663 10 2.91663C12.9153 2.91663 14.6478 3.02296 15.6262 3.12116Z"
      fill="#C0C0C0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1311 1.687C12.8601 1.70184 13.4102 2.34541 13.3248 3.06959L13.2272 3.89638C13.153 4.52575 12.6195 4.99996 11.9858 4.99996H8.01613C7.38242 4.99996 6.84896 4.52575 6.77475 3.89638L6.67721 3.06959C6.5918 2.34542 7.14184 1.70185 7.87088 1.687C8.50434 1.6741 9.21192 1.66663 10.0011 1.66663C10.7903 1.66663 11.4977 1.6741 12.1311 1.687Z"
      fill="#F02425"
    />
    <path
      d="M5.8335 13.5417C5.8335 12.9442 6.21583 12.5046 6.8132 12.5002C6.83345 12.5001 6.85412 12.5 6.87516 12.5C6.8962 12.5 6.91687 12.5001 6.93712 12.5002C7.5345 12.5046 7.91683 12.9442 7.91683 13.5417C7.91683 14.1391 7.5345 14.5788 6.93712 14.5831C6.91687 14.5833 6.8962 14.5833 6.87516 14.5833C6.85412 14.5833 6.83345 14.5833 6.8132 14.5831C6.21583 14.5788 5.8335 14.1391 5.8335 13.5417Z"
      fill="white"
    />
    <path
      d="M9.17628 13.19C9.19774 12.8138 9.48645 12.5632 9.86237 12.5363C10.1396 12.5165 10.5254 12.5 11.042 12.5C11.5586 12.5 11.9444 12.5165 12.2216 12.5363C12.5975 12.5632 12.8862 12.8138 12.9077 13.19C12.9135 13.2909 12.917 13.4075 12.917 13.5417C12.917 13.6759 12.9135 13.7924 12.9077 13.8933C12.8862 14.2695 12.5975 14.5201 12.2216 14.547C11.9444 14.5669 11.5586 14.5833 11.042 14.5833C10.5254 14.5833 10.1396 14.5669 9.86237 14.547C9.48645 14.5201 9.19774 14.2695 9.17628 13.8933C9.17053 13.7924 9.16699 13.6759 9.16699 13.5417C9.16699 13.4075 9.17053 13.2909 9.17628 13.19Z"
      fill="white"
    />
    <path
      d="M5.8335 9.37492C5.8335 8.7775 6.21583 8.33784 6.8132 8.33346C6.83345 8.33334 6.85412 8.33325 6.87516 8.33325C6.8962 8.33325 6.91687 8.33334 6.93712 8.33346C7.5345 8.33784 7.91683 8.7775 7.91683 9.37492C7.91683 9.97234 7.5345 10.412 6.93712 10.4164C6.91687 10.4165 6.8962 10.4166 6.87516 10.4166C6.85412 10.4166 6.83345 10.4165 6.8132 10.4164C6.21583 10.412 5.8335 9.97234 5.8335 9.37492Z"
      fill="white"
    />
    <path
      d="M9.18453 8.9625C9.21674 8.61813 9.50003 8.40859 9.84508 8.38475C10.2192 8.35892 10.8071 8.33325 11.667 8.33325C12.5269 8.33325 13.1148 8.35892 13.4889 8.38475C13.834 8.40859 14.1172 8.61813 14.1495 8.9625C14.1601 9.07654 14.167 9.21288 14.167 9.37492C14.167 9.53696 14.1601 9.67329 14.1495 9.78734C14.1172 10.1317 13.834 10.3413 13.4889 10.3651C13.1148 10.3909 12.5269 10.4166 11.667 10.4166C10.8071 10.4166 10.2192 10.3909 9.84508 10.3651C9.50003 10.3413 9.21674 10.1317 9.18453 9.78734C9.17387 9.67329 9.16699 9.53696 9.16699 9.37492C9.16699 9.21288 9.17387 9.07654 9.18453 8.9625Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'mail'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.87055 16.2707C1.96941 17.0482 2.49113 17.6799 3.25906 17.8362C4.39592 18.0676 6.47017 18.3334 10.0002 18.3334C13.5302 18.3334 15.6045 18.0676 16.7413 17.8362C17.5093 17.6799 18.031 17.0482 18.1299 16.2707C18.2955 14.968 18.3265 13.8413 18.3322 13.4661C18.3336 13.3771 18.3288 13.2888 18.3192 13.2004C17.9005 9.33354 17.3117 5.66691 16.9676 3.66201C16.8021 2.69759 16.0725 1.95604 15.0983 1.86339C14.0455 1.76326 12.4067 1.66675 10.0002 1.66675C7.59379 1.66675 5.95487 1.76326 4.90208 1.86339C3.92798 1.95604 3.19833 2.69759 3.0328 3.662C2.68868 5.66691 2.09993 9.33354 1.6812 13.2004C1.67162 13.2889 1.66683 13.3771 1.6682 13.466C1.67397 13.8412 1.70488 14.968 1.87055 16.2707Z"
      fill="#C0C0C0"
    />
    <path
      d="M9.99984 18.3333C6.4698 18.3333 4.39555 18.0674 3.25869 17.836C2.49076 17.6797 1.96904 17.048 1.87018 16.2706C1.66649 14.6688 1.6665 13.3333 1.6665 13.3333H6.48255C7.00713 13.3333 7.47284 13.6689 7.63871 14.1666C7.80463 14.6643 8.27034 14.9999 8.79492 14.9999H11.2048C11.7293 14.9999 12.195 14.6643 12.361 14.1666C12.5268 13.6689 12.9925 13.3333 13.5171 13.3333H18.3332C18.3332 13.3333 18.3332 14.6688 18.1295 16.2706C18.0306 17.048 17.5089 17.6798 16.741 17.836C15.6041 18.0674 13.5299 18.3333 9.99984 18.3333Z"
      fill="#F02425"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4995 5.07388C6.12212 5.09296 5.83333 5.40546 5.83333 5.78329V6.30004C5.83333 6.67788 6.12212 6.99038 6.4995 7.00946C7.15487 7.04263 8.30512 7.08333 10 7.08333C11.6949 7.08333 12.8451 7.04263 13.5005 7.00946C13.8779 6.99038 14.1667 6.67788 14.1667 6.30004V5.78329C14.1667 5.40546 13.8779 5.09296 13.5005 5.07388C12.8451 5.04071 11.6949 5 10 5C8.30512 5 7.15487 5.04071 6.4995 5.07388ZM5.68971 8.83458C5.30258 8.85012 5 9.16917 5 9.55658V10.0268C5 10.4142 5.30258 10.7332 5.68971 10.7488C6.54304 10.783 8.11629 10.8333 10 10.8333C11.8837 10.8333 13.457 10.783 14.3103 10.7488C14.6974 10.7332 15 10.4142 15 10.0268V9.55658C15 9.16917 14.6974 8.85012 14.3103 8.83458C13.457 8.80029 11.8837 8.75 10 8.75C8.11629 8.75 6.54304 8.80029 5.68971 8.83458Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'payments'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.22 1.73826C14.3205 1.74425 15.172 1.83886 16.6665 3.33328C18.174 4.84088 18.2557 5.66054 18.2602 5.74492C18.2602 5.74679 18.2603 5.74871 18.2604 5.75063C18.2604 5.75167 18.2604 5.75217 18.2604 5.75217L18.2605 5.75213C18.3037 6.83725 18.3332 8.22708 18.3332 9.99996C18.3332 13.8706 18.1927 16.3367 18.0749 17.7244C18.0205 18.3658 17.2945 18.6483 16.8 18.2362L15.4165 17.0833L14.2773 18.2225C13.977 18.5228 13.499 18.5493 13.1674 18.284L11.6665 17.0833L10.5891 18.1607C10.2637 18.4861 9.736 18.4861 9.41059 18.1607L8.33317 17.0833L6.83225 18.284C6.50067 18.5493 6.02267 18.5228 5.72242 18.2225L4.58317 17.0833L3.19971 18.2362C2.70519 18.6483 1.97922 18.3658 1.92477 17.7244C1.80696 16.3367 1.6665 13.8706 1.6665 9.99996C1.6665 6.43775 1.78547 4.42208 1.89626 3.31723C1.97477 2.53426 2.53414 1.9749 3.3171 1.89638C4.42196 1.78559 6.43763 1.66663 9.99984 1.66663C11.7577 1.66663 13.139 1.6956 14.22 1.73826Z"
      fill="#C0C0C0"
    />
    <path
      d="M18.2605 5.75258C17.8958 5.79746 17.3804 5.83371 16.6665 5.83371C16.1419 5.83371 15.7245 5.81412 15.3954 5.78596C14.6787 5.72458 14.213 5.17662 14.1855 4.45783C14.1741 4.16038 14.1665 3.79063 14.1665 3.33371C14.1665 2.59498 14.1864 2.0841 14.21 1.73828C14.21 1.73828 15.0725 1.73977 16.6665 3.33371C18.2605 4.92766 18.2605 5.75258 18.2605 5.75258Z"
      fill="#F02425"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0242 7.69803C13.4399 7.93199 13.5753 8.46753 13.3105 8.86424C11.8675 11.0259 10.571 12.4436 9.83649 13.1742C9.46636 13.5424 8.90444 13.5906 8.48803 13.2757C7.83536 12.782 6.82657 11.906 5.84982 10.6037C5.61261 10.2875 5.65303 9.84724 5.92869 9.56395L6.1504 9.33611C6.4609 9.01699 6.96828 8.99782 7.28519 9.31057C8.23711 10.25 9.05986 11.2659 9.05986 11.2659C9.05986 11.2659 10.2162 9.5972 11.8877 7.7792C12.1452 7.49915 12.5621 7.43799 12.8936 7.62453L13.0242 7.69803Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'referral'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3945 10.754C17.522 10.6975 17.6291 10.605 17.6717 10.4723C17.7427 10.2509 17.8332 9.80996 17.8332 8.99992C17.8332 8.18988 17.7427 7.74892 17.6717 7.52759C17.6291 7.39479 17.522 7.30229 17.3945 7.24584C16.8588 7.00888 15.3044 6.35384 13.9116 6.08325C13.9116 6.08325 13.6665 6.33325 13.6665 8.99992C13.6665 11.6666 13.9116 11.9166 13.9116 11.9166C15.3044 11.646 16.8588 10.991 17.3945 10.754Z"
      fill="#F02425"
    />
    <path
      d="M8.19375 16.406C8.03291 17.0716 7.37475 17.4704 6.73504 17.2899L4.95571 16.7878C4.23558 16.5846 3.87516 15.7506 4.209 15.0599L5.92937 11.5L9.0835 12.7238L8.19375 16.406Z"
      fill="#F02425"
    />
    <path
      d="M2.50475 12.5335C1.94582 12.456 1.46521 12.1034 1.35948 11.5343C1.25912 10.994 1.1665 10.1768 1.1665 9C1.1665 7.82325 1.25912 7.00596 1.35948 6.46571C1.46521 5.89658 1.94582 5.54396 2.50475 5.46654C4.5835 5.17854 9.83596 4.24525 13.5516 1.80967C14.4801 1.20098 15.6352 1.49946 15.7996 2.6184C15.9928 3.93388 16.1665 5.96142 16.1665 9C16.1665 12.0386 15.9928 14.0661 15.7996 15.3816C15.6352 16.5005 14.4801 16.799 13.5515 16.1903C9.83596 13.7548 4.5835 12.8215 2.50475 12.5335Z"
      fill="#C0C0C0"
    />
    <path
      d="M4.7839 10.6806C4.87276 11.0004 6.84772 12.1999 7.17973 12.238V5.87927C6.68687 5.93598 4.91761 6.67961 4.7839 7.16087C4.67967 7.53603 4.5835 8.10356 4.5835 8.92072C4.5835 9.73787 4.67967 10.3054 4.7839 10.6806Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'requests'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.89813 1.69325C7.89813 1.69325 6.878 2.08341 5.00302 3.95841C3.13488 5.82654 2.74312 6.84846 2.74028 6.85591C2.69475 7.74612 2.6665 8.78716 2.6665 10.0001C2.6665 13.619 2.91797 15.708 3.12968 16.8254C3.26569 17.5432 3.82581 18.0352 4.5522 18.1136C5.53271 18.2194 7.26296 18.3334 10.1665 18.3334C13.07 18.3334 14.8003 18.2194 15.7808 18.1136C16.5072 18.0352 17.0673 17.5432 17.2033 16.8254C17.415 15.708 17.6665 13.619 17.6665 10.0001C17.6665 6.38112 17.415 4.29221 17.2033 3.17481C17.0673 2.45697 16.5072 1.96496 15.7808 1.88657C14.8003 1.78077 13.07 1.66675 10.1665 1.66675C9.31246 1.66675 8.55992 1.67663 7.89813 1.69325Z"
      fill="#C0C0C0"
    />
    <path
      d="M2.74023 6.85629C3.94005 7.15804 5.35663 7.17808 6.58017 6.80433C7.09746 6.64629 7.50079 6.24333 7.67538 5.73142C8.11029 4.45612 8.21775 2.89513 7.89808 1.6936C7.89808 1.6936 6.878 2.08377 5.00299 3.95877C3.12799 5.83379 2.74023 6.85629 2.74023 6.85629Z"
      fill="#F02425"
    />
    <path
      d="M6.42627 13.8495C6.42627 14.1701 6.82138 14.4362 7.34349 14.454C8.07848 14.479 9.25526 14.5066 10.8738 14.5066C12.282 14.5066 13.1887 14.4858 13.7524 14.4638C14.2359 14.445 14.58 14.2006 14.58 13.9035V13.4431C14.58 13.146 14.2359 12.9016 13.7524 12.8828C13.1887 12.8608 12.282 12.84 10.8738 12.84C9.25526 12.84 8.07848 12.8675 7.34349 12.8926C6.82138 12.9104 6.42627 13.1765 6.42627 13.4971V13.8495Z"
      fill="white"
    />
    <path
      d="M6.42627 10.5793C6.42627 10.8723 6.7673 11.1164 7.25284 11.1377C7.68832 11.1568 8.31865 11.1733 9.19604 11.1733C10.0734 11.1733 10.7038 11.1568 11.1392 11.1377C11.6248 11.1164 11.9658 10.8723 11.9658 10.5793V10.1006C11.9658 9.80759 11.6248 9.56347 11.1392 9.54217C10.7038 9.52309 10.0734 9.50659 9.19604 9.50659C8.31865 9.50659 7.68832 9.52309 7.25284 9.54217C6.7673 9.56347 6.42627 9.80759 6.42627 10.1006V10.5793Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'sessions'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.08318 14.0686C3.15539 14.7973 3.65057 15.3546 4.37225 15.4783C5.35875 15.6473 7.10234 15.8333 9.99984 15.8333C12.8973 15.8333 14.6409 15.6473 15.6274 15.4783C16.3491 15.3546 16.8443 14.7973 16.9165 14.0686C17.0002 13.2244 17.0832 11.8225 17.0832 9.58325C17.0832 7.34396 17.0002 5.94209 16.9165 5.09788C16.8443 4.36925 16.3491 3.81187 15.6274 3.68821C14.6409 3.51917 12.8973 3.33325 9.99984 3.33325C7.10234 3.33325 5.35875 3.51917 4.37225 3.68821C3.65057 3.81187 3.15539 4.36925 3.08318 5.09788C2.99952 5.94209 2.9165 7.34396 2.9165 9.58325C2.9165 11.8225 2.99952 13.2244 3.08318 14.0686Z"
      fill="#C0C0C0"
    />
    <path
      d="M9.9999 16.6666C6.72903 16.6666 4.70803 16.5525 3.52452 16.4437C2.61758 16.3603 1.94897 15.6956 1.86779 14.7884C1.85225 14.6147 1.83668 14.4226 1.82147 14.2106C1.7873 13.7345 2.16824 13.3333 2.64555 13.3333H7.19961C7.4619 13.3333 7.69478 13.5011 7.77769 13.7499C7.86065 13.9988 8.09353 14.1666 8.35582 14.1666H11.6441C11.9064 14.1666 12.1392 13.9988 12.2222 13.7499C12.3051 13.5011 12.5379 13.3333 12.8002 13.3333H17.3543C17.8316 13.3333 18.2125 13.7345 18.1784 14.2106C18.1632 14.4226 18.1476 14.6147 18.132 14.7884C18.0509 15.6956 17.3822 16.3603 16.4753 16.4437C15.2918 16.5525 13.2708 16.6666 9.9999 16.6666Z"
      fill="#F02425"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.08892 9.71144C9.1135 9.47644 8.94113 9.26561 8.70554 9.24798C8.33621 9.2204 8.00275 9.1984 7.72996 9.18161C7.40467 9.16165 7.22642 8.79823 7.42121 8.53698C7.98554 7.78003 9.05138 6.43269 10.3336 5.21007C10.5964 4.95952 11.0147 5.16015 10.9943 5.52261C10.9537 6.2414 10.9019 6.85715 10.848 7.37269C10.8234 7.60769 10.9957 7.81853 11.2313 7.83611C11.6007 7.86373 11.9341 7.88573 12.2069 7.90248C12.5322 7.92248 12.7105 8.2859 12.5157 8.54715C11.9513 9.30407 10.8855 10.6514 9.60325 11.8741C9.3405 12.1246 8.92213 11.924 8.94263 11.5615C8.98321 10.8427 9.035 10.227 9.08892 9.71144Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'settings'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6665 5.41675C1.6665 3.23621 3.28504 1.84358 5.46359 1.75052C6.60367 1.70183 8.08834 1.66675 9.99984 1.66675C11.9113 1.66675 13.396 1.70183 14.5361 1.75052C16.7146 1.84358 18.3332 3.23621 18.3332 5.41675C18.3332 7.59729 16.7146 8.98991 14.5361 9.08296C13.396 9.13166 11.9113 9.16675 9.99984 9.16675C8.08834 9.16675 6.60367 9.13166 5.46359 9.08296C3.28504 8.98991 1.6665 7.59729 1.6665 5.41675Z"
      fill="#F02425"
    />
    <path
      d="M14.5833 7.49992C15.7339 7.49992 16.6667 6.56718 16.6667 5.41659C16.6667 4.26599 15.7339 3.33325 14.5833 3.33325C13.4327 3.33325 12.5 4.26599 12.5 5.41659C12.5 6.56718 13.4327 7.49992 14.5833 7.49992Z"
      fill="white"
    />
    <path
      d="M18.3332 14.5833C18.3332 12.4027 16.7146 11.0101 14.5361 10.917C13.396 10.8683 11.9113 10.8333 9.99984 10.8333C8.08834 10.8333 6.60367 10.8683 5.46359 10.917C3.28504 11.0101 1.6665 12.4027 1.6665 14.5833C1.6665 16.7638 3.28504 18.1564 5.46359 18.2495C6.60367 18.2982 8.08834 18.3333 9.99984 18.3333C11.9113 18.3333 13.396 18.2982 14.5361 18.2495C16.7146 18.1564 18.3332 16.7638 18.3332 14.5833Z"
      fill="#C0C0C0"
    />
    <path
      d="M5.41667 16.6667C4.26607 16.6667 3.33333 15.7339 3.33333 14.5833C3.33333 13.4327 4.26607 12.5 5.41667 12.5C6.56726 12.5 7.5 13.4327 7.5 14.5833C7.5 15.7339 6.56726 16.6667 5.41667 16.6667Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'staff'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98163 1.65065C7.98163 1.65065 6.9615 2.04081 5.08652 3.91581C3.21838 5.78394 2.82662 6.80585 2.82378 6.81331C2.77825 7.70352 2.75 8.74456 2.75 9.95748C2.75 13.5764 3.00146 15.6654 3.21317 16.7828C3.34918 17.5006 3.9093 17.9926 4.6357 18.071C5.61621 18.1768 7.34646 18.2908 10.25 18.2908C13.1535 18.2908 14.8838 18.1768 15.8643 18.071C16.5907 17.9926 17.1508 17.5006 17.2868 16.7828C17.4985 15.6654 17.75 13.5764 17.75 9.95748C17.75 6.33852 17.4985 4.2496 17.2868 3.1322C17.1508 2.41437 16.5907 1.92235 15.8643 1.84397C14.8838 1.73817 13.1535 1.62415 10.25 1.62415C9.39596 1.62415 8.64342 1.63402 7.98163 1.65065Z"
      fill="#C0C0C0"
    />
    <path
      d="M2.82373 6.81369C4.02354 7.11544 5.44012 7.13548 6.66366 6.76173C7.18096 6.60369 7.58429 6.20073 7.75887 5.68881C8.19379 4.41352 8.30125 2.85253 7.98158 1.651C7.98158 1.651 6.9615 2.04117 5.08648 3.91617C3.21148 5.79119 2.82373 6.81369 2.82373 6.81369Z"
      fill="#F02425"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9983 8.76314C14.6848 8.3946 15.1517 7.66988 15.1517 6.8361C15.1517 5.62895 14.1731 4.65039 12.966 4.65039C11.7589 4.65039 10.7803 5.62895 10.7803 6.8361C10.7803 7.66988 11.2471 8.3946 11.9337 8.76314C11.2801 8.99733 10.7294 9.44759 10.3675 10.0278C10.1128 10.4365 10.2857 10.9837 10.7556 11.0892C10.8404 11.1082 10.9283 11.122 11.0191 11.1303C11.5144 11.1755 12.1525 11.2075 12.9659 11.2075C13.7794 11.2075 14.4175 11.1755 14.9128 11.1303C15.0036 11.122 15.0915 11.1082 15.1764 11.0891C15.6462 10.9836 15.8193 10.4365 15.5644 10.0278C15.2026 9.44759 14.6519 8.99733 13.9983 8.76314Z"
      fill="#FF3233"
    />
    <path
      d="M5.25 14.717C5.25 15.0377 5.49229 15.3037 5.81246 15.3215C6.26317 15.3466 6.98479 15.3742 7.97729 15.3742C8.84087 15.3742 9.39683 15.3533 9.74254 15.3314C10.039 15.3126 10.25 15.0681 10.25 14.7711V14.3106C10.25 14.0136 10.039 13.7691 9.74254 13.7504C9.39683 13.7284 8.84087 13.7075 7.97729 13.7075C6.98479 13.7075 6.26317 13.7351 5.81246 13.7602C5.49229 13.778 5.25 14.044 5.25 14.3647V14.717Z"
      fill="white"
    />
    <path
      d="M5.25 11.4469C5.25 11.7398 5.45521 11.9839 5.74737 12.0052C6.00942 12.0243 6.38871 12.0408 6.91667 12.0408C7.44462 12.0408 7.82392 12.0243 8.08596 12.0052C8.37812 11.9839 8.58333 11.7398 8.58333 11.4469V10.9681C8.58333 10.6751 8.37812 10.431 8.08596 10.4097C7.82392 10.3906 7.44462 10.3741 6.91667 10.3741C6.38871 10.3741 6.00942 10.3906 5.74737 10.4097C5.45521 10.431 5.25 10.6751 5.25 10.9681V11.4469Z"
      fill="white"
    />
    <ellipse cx="12.966" cy="6.82223" rx="2.20722" ry="2.17184" fill="white" />
  </svg>

  <svg
    v-else-if="name === 'statistics'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.38699 15.5223C2.45657 16.196 2.8983 16.7293 3.56079 16.8704C4.60648 17.093 6.57768 17.3604 10.0133 17.3604C13.449 17.3604 15.4202 17.093 16.4659 16.8704C17.1284 16.7293 17.5701 16.196 17.6397 15.5223C17.7429 14.5233 17.8602 12.6892 17.8602 9.51358C17.8602 6.33801 17.7429 4.50385 17.6397 3.50488C17.5701 2.83113 17.1284 2.29782 16.4659 2.15679C15.4202 1.93418 13.449 1.66675 10.0133 1.66675C6.57768 1.66675 4.60648 1.93418 3.56079 2.15679C2.8983 2.29782 2.45657 2.83113 2.38699 3.50488C2.28383 4.50385 2.1665 6.33801 2.1665 9.51358C2.1665 12.6892 2.28383 14.5233 2.38699 15.5223Z"
      fill="#C0C0C0"
    />
    <path
      d="M5.45397 13.3436C5.04899 13.3334 4.74223 13.0925 4.70913 12.6933C4.6893 12.4541 4.6748 12.1254 4.6748 11.6755C4.6748 11.2256 4.6893 10.897 4.70913 10.6578C4.74223 10.2586 5.04899 10.0177 5.45397 10.0075C5.63116 10.0031 5.85269 10 6.12716 10C6.40163 10 6.62316 10.0031 6.80035 10.0075C7.20534 10.0177 7.51208 10.2586 7.54519 10.6578C7.56501 10.897 7.57951 11.2256 7.57951 11.6755C7.57951 12.1254 7.56501 12.4541 7.54519 12.6933C7.51208 13.0925 7.20534 13.3334 6.80035 13.3436C6.62316 13.348 6.40163 13.3511 6.12716 13.3511C5.85269 13.3511 5.63116 13.348 5.45397 13.3436Z"
      fill="#FF3233"
    />
    <path
      d="M13.3139 13.3411C12.842 13.3227 12.5112 12.9947 12.4806 12.5288C12.4489 12.0456 12.4204 11.2608 12.4204 10C12.4204 8.73918 12.4489 7.95439 12.4806 7.47123C12.5112 7.00526 12.842 6.67727 13.3139 6.65886C13.4695 6.65278 13.6544 6.64893 13.8728 6.64893C14.0911 6.64893 14.276 6.65278 14.4317 6.65886C14.9035 6.67727 15.2343 7.00526 15.2649 7.47123C15.2967 7.95439 15.3251 8.73918 15.3251 10C15.3251 11.2608 15.2967 12.0456 15.2649 12.5288C15.2343 12.9947 14.9035 13.3227 14.4317 13.3411C14.276 13.3472 14.0911 13.3511 13.8728 13.3511C13.6544 13.3511 13.4695 13.3472 13.3139 13.3411Z"
      fill="#FF3233"
    />
    <path
      d="M9.40794 13.343C8.95582 13.3294 8.62628 13.036 8.59379 12.5899C8.56828 12.2393 8.54785 11.7204 8.54785 10.9575C8.54785 10.1946 8.56828 9.67568 8.59379 9.32504C8.62628 8.87892 8.95582 8.58551 9.40794 8.57191C9.57038 8.56703 9.76604 8.56384 10.0002 8.56384C10.2344 8.56384 10.43 8.56703 10.5925 8.57191C11.0446 8.58551 11.3741 8.87892 11.4066 9.32504C11.4321 9.67568 11.4526 10.1946 11.4526 10.9575C11.4526 11.7204 11.4321 12.2393 11.4066 12.5899C11.3741 13.036 11.0446 13.3294 10.5925 13.343C10.43 13.3479 10.2344 13.3511 10.0002 13.3511C9.76604 13.3511 9.57038 13.3479 9.40794 13.343Z"
      fill="white"
    />
  </svg>

  <svg
    v-else-if="name === 'status-no'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8.00004C1.3335 4.31804 4.31816 1.33337 8.00016 1.33337C11.6822 1.33337 14.6668 4.31804 14.6668 8.00004C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM8.00016 7.05737L6.11483 5.17137L5.1715 6.11471L7.0575 8.00004L5.1715 9.88537L6.11483 10.8287L8.00016 8.94271L9.8855 10.8287L10.8288 9.88537L8.94283 8.00004L10.8288 6.11471L9.8855 5.17137L8.00016 7.05737Z"
      fill="#F02425"
    />
  </svg>

  <svg
    v-else-if="name === 'status-yes'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00016 14.6667C4.31816 14.6667 1.3335 11.682 1.3335 8.00004C1.3335 4.31804 4.31816 1.33337 8.00016 1.33337C11.6822 1.33337 14.6668 4.31804 14.6668 8.00004C14.6668 11.682 11.6822 14.6667 8.00016 14.6667ZM7.3355 11.3334L12.0488 6.61937L11.1062 5.67671L7.3355 9.44804L5.4495 7.56204L4.50683 8.50471L7.3355 11.3334Z"
      fill="#24A519"
    />
  </svg>

  <svg
    v-else-if="name === 'support'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18.25C5.44364 18.25 1.75 14.5564 1.75 10C1.75 5.44364 5.44364 1.75 10 1.75C14.5564 1.75 18.25 5.44364 18.25 10C18.25 14.5564 14.5564 18.25 10 18.25ZM10 13.3C8.17746 13.3 6.7 11.8225 6.7 10C6.7 8.17746 8.17746 6.7 10 6.7C11.8225 6.7 13.3 8.17746 13.3 10C13.3 11.8225 11.8225 13.3 10 13.3Z"
      fill="#F02425"
    />
    <path
      d="M12.9448 11.4904C13.1719 11.0428 13.2998 10.5363 13.2998 10C13.2998 9.521 13.1977 9.06579 13.0141 8.65502C14.6752 7.7652 16.1907 6.85546 17.3034 6.15979C17.9077 7.30678 18.2498 8.61346 18.2498 10C18.2498 11.4088 17.8967 12.7351 17.2741 13.8952C16.1487 13.2305 14.6185 12.3576 12.9448 11.4904Z"
      fill="#C0C0C0"
    />
    <path
      d="M8.5489 7.0353C8.98682 6.82057 9.4793 6.7 9.99992 6.7C10.5287 6.7 11.0284 6.82434 11.4714 7.0454C12.3633 5.38357 13.2732 3.87009 13.9657 2.76392C12.7889 2.1176 11.4373 1.75 9.99992 1.75C8.6041 1.75 7.28924 2.09665 6.13672 2.70854C6.79365 3.81708 7.67149 5.34951 8.5489 7.0353Z"
      fill="#C0C0C0"
    />
    <path
      d="M2.77593 13.9876C2.12216 12.8057 1.75 11.4463 1.75 10C1.75 8.57433 2.11166 7.23303 2.74824 6.06299C3.83725 6.74735 5.35285 7.6631 7.0269 8.56631C6.81742 8.99987 6.7 9.48627 6.7 10C6.7 10.5702 6.84461 11.1067 7.09918 11.5747C5.41072 12.4547 3.87863 13.3335 2.77593 13.9876Z"
      fill="#C0C0C0"
    />
    <path
      d="M13.9655 17.2361C12.7888 17.8824 11.4373 18.25 9.99988 18.25C8.60406 18.25 7.28924 17.9034 6.13672 17.2915C6.79369 16.1829 7.67153 14.6505 8.5489 12.9647C8.98682 13.1794 9.47927 13.3 9.99988 13.3C10.5286 13.3 11.0283 13.1757 11.4713 12.9546C12.3632 14.6165 13.2731 16.1299 13.9655 17.2361Z"
      fill="#C0C0C0"
    />
  </svg>

  <svg
    v-else-if="name === 'coin-dollar'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12.75C7 13.9957 8.03898 15 9.32984 15H11.964C13.087 15 14 14.1212 14 13.0397C14 11.8616 13.4438 11.4464 12.6147 11.176L8.38531 9.82403C7.55622 9.55365 7 9.13841 7 7.9603C7 6.87876 7.91304 6 9.03598 6H11.6702C12.961 6 14 7.00429 14 8.25"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 5.25V15.75"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'arrow-left'"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_92_6386)">
      <path
        d="M5.12219 8.02576L10.5869 13.4905L9.02588 15.0515L2.00012 8.02576L9.02588 1L10.5869 2.56103L5.12219 8.02576Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_92_6386">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'arrow-stick-bottom'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8112 12.6265L10.5 17.9377L5.18872 12.6265"
      fill="transparent"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 3.0625V17.7887"
      fill="transparent"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'arrow-stick-top'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8112 8.37375L10.5 3.0625L5.18872 8.37375"
      fill="transparent"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 17.9377V3.21143"
      fill="transparent"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'close'"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_108_6634)">
      <path
        d="M16 16L0 0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0 16L16 0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_108_6634">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'date'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_66_4435)">
      <path
        d="M11.9998 2.33325H3.99984C3.26346 2.33325 2.6665 2.93021 2.6665 3.66659V11.6666C2.6665 12.403 3.26346 12.9999 3.99984 12.9999H11.9998C12.7362 12.9999 13.3332 12.403 13.3332 11.6666V3.66659C13.3332 2.93021 12.7362 2.33325 11.9998 2.33325Z"
        fill="transparent"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6665 1V3.66667"
        fill="transparent"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3335 1V3.66667"
        fill="transparent"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.6665 6.33325H13.3332"
        fill="transparent"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 9.66675H9.33317"
        fill="transparent"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_4435">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'exit'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_137_11427)">
      <path
        d="M10.3077 11.2308V13.0769C10.3077 13.3217 10.2104 13.5565 10.0373 13.7296C9.86422 13.9027 9.62943 14 9.38462 14H2.92308C2.67826 14 2.44347 13.9027 2.27036 13.7296C2.09725 13.5565 2 13.3217 2 13.0769V2.92308C2 2.67826 2.09725 2.44347 2.27036 2.27036C2.44347 2.09725 2.67826 2 2.92308 2H9.38462C9.62943 2 9.86422 2.09725 10.0373 2.27036C10.2104 2.44347 10.3077 2.67826 10.3077 2.92308V4.76923"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="transparent"
      />
      <path
        d="M7.53857 8H14.0001"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1538 6.15381L14 7.99996L12.1538 9.84612"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_137_11427">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'eye-closed'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.172 9.13342C17.6154 9.53009 17.9887 9.91509 18.2687 10.2184C18.4622 10.4328 18.5693 10.7113 18.5693 11.0001C18.5693 11.2889 18.4622 11.5674 18.2687 11.7818C17.0437 13.1001 14.2554 15.6668 11.0004 15.6668H10.5337"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.3484 14.6517C5.99697 13.8948 4.77587 12.9258 3.73173 11.7817C3.53825 11.5673 3.43115 11.2888 3.43115 11C3.43115 10.7113 3.53825 10.4327 3.73173 10.2184C4.95673 8.90004 7.74506 6.33337 11.0001 6.33337C12.2831 6.36019 13.5389 6.70923 14.6517 7.34837"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.4168 4.58337L4.5835 17.4167"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.35484 12.6451C8.91665 12.2095 8.66911 11.6179 8.6665 11.0001C8.6665 10.3812 8.91234 9.78775 9.34992 9.35017C9.78751 8.91258 10.381 8.66675 10.9998 8.66675C11.6177 8.66935 12.2093 8.9169 12.6448 9.35508"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0298 12.1667C12.8222 12.5218 12.5243 12.8156 12.1665 13.0184"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'eye-opened'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1387 10.2323C18.3287 10.4428 18.4339 10.7163 18.4339 11C18.4339 11.2836 18.3287 11.5571 18.1387 11.7677C16.9356 13.0625 14.197 15.5833 11.0002 15.5833C7.80328 15.5833 5.06474 13.0625 3.86162 11.7677C3.67159 11.5571 3.56641 11.2836 3.56641 11C3.56641 10.7163 3.67159 10.4428 3.86162 10.2323C5.06474 8.93746 7.80328 6.41663 11.0002 6.41663C14.197 6.41663 16.9356 8.93746 18.1387 10.2323Z"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0002 13.2916C12.2658 13.2916 13.2918 12.2656 13.2918 10.9999C13.2918 9.73427 12.2658 8.70825 11.0002 8.70825C9.73451 8.70825 8.7085 9.73427 8.7085 10.9999C8.7085 12.2656 9.73451 13.2916 11.0002 13.2916Z"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'left-doubled'"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_130_8263)">
      <path
        d="M9.96928 13.4319L13.4165 16.8792L12.4318 17.8639L7.99984 13.4319L12.4318 9L13.4165 9.98472L9.96928 13.4319Z"
      />
      <path
        d="M14.9693 13.4319L18.4165 16.8792L17.4318 17.8639L12.9998 13.4319L17.4318 9L18.4165 9.98472L14.9693 13.4319Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_130_8263">
        <rect width="26" height="26" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'plus'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_108_7573)">
      <path
        d="M8.00003 12.2426V3.75736"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.75736 7.99997H12.2426"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_108_7573">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'refresh'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="transparent"
      d="M9.00449 6.33333V12.0567L10.2232 10.838L11.1665 11.7813L8.33782 14.6093L5.50915 11.7813L6.45249 10.838L7.67115 12.0567V6.33333H9.00449ZM8.33782 1C9.48247 1.00005 10.5872 1.42074 11.4419 2.18208C12.2967 2.94342 12.8419 3.99231 12.9738 5.12933C13.8033 5.35554 14.527 5.86607 15.0183 6.57169C15.5096 7.2773 15.7373 8.13313 15.6616 8.98959C15.5859 9.84606 15.2116 10.6487 14.6042 11.2572C13.9968 11.8657 13.1948 12.2414 12.3385 12.3187V10.976C12.6453 10.9322 12.9402 10.8277 13.2062 10.6686C13.4722 10.5096 13.7038 10.2991 13.8875 10.0496C14.0713 9.80006 14.2035 9.51642 14.2764 9.21523C14.3493 8.91404 14.3615 8.60134 14.3123 8.29538C14.2631 7.98942 14.1534 7.69634 13.9896 7.43323C13.8259 7.17013 13.6114 6.94228 13.3586 6.76298C13.1059 6.58368 12.8199 6.45653 12.5175 6.38894C12.2151 6.32135 11.9022 6.31469 11.5972 6.36933C11.7016 5.88326 11.6959 5.37998 11.5806 4.89637C11.4653 4.41276 11.2433 3.96106 10.9309 3.57435C10.6184 3.18765 10.2234 2.87574 9.7748 2.66146C9.32618 2.44719 8.83532 2.33598 8.33815 2.33598C7.84099 2.33598 7.35012 2.44719 6.9015 2.66146C6.45289 2.87574 6.05788 3.18765 5.74542 3.57435C5.43296 3.96106 5.21096 4.41276 5.09567 4.89637C4.98039 5.37998 4.97475 5.88326 5.07915 6.36933C4.47092 6.25511 3.84223 6.38719 3.33138 6.73651C2.82053 7.08583 2.46937 7.62377 2.35515 8.232C2.24093 8.84023 2.37301 9.46892 2.72233 9.97977C3.07165 10.4906 3.60959 10.8418 4.21782 10.956L4.33782 10.976V12.3187C3.48145 12.2416 2.67942 11.8659 2.07191 11.2575C1.46439 10.649 1.09004 9.84637 1.01427 8.98988C0.938501 8.1334 1.16614 7.27752 1.6574 6.57185C2.14866 5.86618 2.87229 5.35559 3.70182 5.12933C3.83362 3.99225 4.37876 2.94327 5.23354 2.18191C6.08832 1.42055 7.19312 0.999909 8.33782 1Z"
    />
  </svg>

  <svg
    v-else-if="name === 'search'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="7.16675"
      r="3.75"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M13.4165 12.5834L10.9165 10.0834"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'top-simple'"
    width="6"
    height="3"
    viewBox="0 0 6 3"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.8875 0L5.775 3H0L2.8875 0Z" />
  </svg>

  <svg
    v-else-if="name === 'trash'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.22217 3.88892L13.7777 3.88892"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.55566 6.77783L6.55566 11.1112"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.44434 6.77783V11.1112"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.94434 3.88892L3.66656 12.5556C3.66656 12.9387 3.81874 13.3061 4.08963 13.577C4.36051 13.8478 4.72791 14 5.111 14L10.8888 14C11.2719 14 11.6393 13.8478 11.9102 13.577C12.181 13.3061 12.3332 12.9387 12.3332 12.5556L13.0554 3.88892"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.8335 3.88889L5.8335 1.72222C5.8335 1.53068 5.90959 1.34698 6.04503 1.21153C6.18047 1.07609 6.36417 1 6.55572 1L9.44461 1C9.63615 1 9.81985 1.07609 9.9553 1.21153C10.0907 1.34698 10.1668 1.53068 10.1668 1.72222V3.88889"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'write'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 14L11.9951 14"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.9972 10.5016L3.99854 11.0413L4.49831 8.00267L11.2253 1.29567C11.3182 1.20198 11.4288 1.12762 11.5506 1.07687C11.6724 1.02613 11.803 1 11.935 1C12.0669 1 12.1976 1.02613 12.3194 1.07687C12.4412 1.12762 12.5518 1.20198 12.6447 1.29567L13.7042 2.35519C13.7979 2.44812 13.8723 2.55867 13.923 2.68047C13.9737 2.80228 13.9999 2.93292 13.9999 3.06488C13.9999 3.19683 13.9737 3.32748 13.923 3.44928C13.8723 3.57109 13.7979 3.68164 13.7042 3.77456L6.9972 10.5016Z"
      fill="transparent"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else-if="name === 'check'"
    width="7"
    height="6"
    viewBox="0 0 7 6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.79126 4.32127L1.67157 3.20158L1.55036 3.08036L1.42914 3.20158L1.0155 3.61521L0.894282 3.73643L1.0155 3.85765L2.67005 5.51219L2.79126 5.63341L2.91248 5.51219L6.45794 1.96674L6.57916 1.84552L6.45794 1.7243L6.0443 1.31067L5.92308 1.18945L5.80187 1.31067L2.79126 4.32127Z"
      stroke-width="0.342857"
      stroke-linecap="round"
    />
  </svg>

  <svg
    v-else-if="name === 'check-rounded'"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10154 14.8404C4.41954 14.8404 1.43488 11.8557 1.43488 8.17375C1.43488 4.49175 4.41954 1.50708 8.10154 1.50708C11.7835 1.50708 14.7682 4.49175 14.7682 8.17375C14.7682 11.8557 11.7835 14.8404 8.10154 14.8404ZM7.43688 11.5071L12.1502 6.79308L11.2075 5.85041L7.43688 9.62175L5.55088 7.73575L4.60821 8.67841L7.43688 11.5071Z"
      fill="#24A519"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'cancel-rounded'"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10154 14.8404C4.41954 14.8404 1.43488 11.8557 1.43488 8.17375C1.43488 4.49175 4.41954 1.50708 8.10154 1.50708C11.7835 1.50708 14.7682 4.49175 14.7682 8.17375C14.7682 11.8557 11.7835 14.8404 8.10154 14.8404ZM8.10154 7.23108L6.21621 5.34508L5.27288 6.28841L7.15888 8.17375L5.27288 10.0591L6.21621 11.0024L8.10154 9.11641L9.98688 11.0024L10.9302 10.0591L9.04421 8.17375L10.9302 6.28841L9.98688 5.34508L8.10154 7.23108Z"
      fill="#F02425"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'wallet-filled'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.86199 3.24992H12.9844C13.1012 3.24986 13.2179 3.25727 13.3337 3.2721C13.2945 2.99641 13.1998 2.73152 13.0554 2.49341C12.9109 2.2553 12.7198 2.04891 12.4935 1.88667C12.2671 1.72443 12.0103 1.6097 11.7384 1.54941C11.4665 1.48912 11.1853 1.4845 10.9116 1.53585L3.01179 2.88455C2.90371 2.90111 2.79444 2.9195 2.68504 2.93992H2.67812C2.62984 2.94915 2.58206 2.96007 2.53486 2.97264C1.34757 3.27143 1 4.34799 1 4.99994V5.75241C0.989734 5.83409 0.984466 5.91675 0.984375 5.99994V11.9999C0.984954 12.5302 1.19585 13.0386 1.5708 13.4135C1.94575 13.7885 2.45412 13.9994 2.98438 13.9999H12.9844C13.5146 13.9994 14.023 13.7885 14.3979 13.4135C14.7729 13.0386 14.9838 12.5302 14.9844 11.9999V5.99994C14.9838 5.46968 14.7729 4.96131 14.3979 4.58636C14.023 4.21142 13.5146 4.00052 12.9844 3.99994H5C4.47093 3.99994 4.41295 3.35873 4.86199 3.24992ZM11.5 9.99994C11.3022 9.99994 11.1089 9.94129 10.9444 9.83141C10.78 9.72153 10.6518 9.56535 10.5761 9.38262C10.5004 9.1999 10.4806 8.99883 10.5192 8.80485C10.5578 8.61087 10.653 8.43268 10.7929 8.29283C10.9327 8.15298 11.1109 8.05774 11.3049 8.01915C11.4989 7.98057 11.7 8.00037 11.8827 8.07606C12.0654 8.15175 12.2216 8.27992 12.3315 8.44437C12.4414 8.60882 12.5 8.80216 12.5 8.99994C12.5 9.26516 12.3946 9.51951 12.2071 9.70705C12.0196 9.89458 11.7652 9.99994 11.5 9.99994Z"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'wallet'"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_798_8231)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3213 2.01584C14.7105 1.94267 15.1111 1.95579 15.4948 2.05432C15.8806 2.15341 16.2398 2.33644 16.5468 2.59035C16.8538 2.84425 17.1009 3.16278 17.2706 3.52319C17.4403 3.88361 17.5283 4.27704 17.5284 4.6754V4.67561V5.29107C18.7559 5.51096 19.6875 6.58411 19.6875 7.87485V15.7498C19.6875 17.1996 18.5122 18.3748 17.0625 18.3748H3.9375C2.48775 18.3748 1.3125 17.1996 1.3125 15.7498V7.87485L1.3125 7.87326V6.52151V6.52131C1.31218 5.89113 1.53172 5.28058 1.9333 4.79491C2.33497 4.30913 2.89358 3.97875 3.51278 3.86075C3.51691 3.85996 3.52106 3.85921 3.52521 3.8585L14.3213 2.01584ZM16.2159 4.67582V5.24985H3.9375C3.71517 5.24985 3.4993 5.27749 3.29313 5.32952C3.43355 5.24383 3.58905 5.18304 3.75287 5.15112L14.5479 3.30864C14.5501 3.30826 14.5523 3.30788 14.5545 3.30749C14.5564 3.30713 14.5584 3.30677 14.5604 3.30639C14.7619 3.26797 14.9695 3.27452 15.1683 3.32557C15.3671 3.37662 15.5521 3.4709 15.7102 3.6017C15.8684 3.7325 15.9957 3.89659 16.0831 4.08226C16.1705 4.26786 16.2158 4.47046 16.2159 4.67561V4.67582ZM2.625 15.7498L2.625 8.53108V7.87355C2.6257 7.14927 3.21306 6.56235 3.9375 6.56235H17.0625C17.7874 6.56235 18.375 7.14997 18.375 7.87485V15.7498C18.375 16.4747 17.7874 17.0623 17.0625 17.0623H3.9375C3.21263 17.0623 2.625 16.4747 2.625 15.7498ZM14.3646 12.9037C14.5804 13.0479 14.8342 13.1248 15.0938 13.1248C15.4418 13.1248 15.7757 12.9866 16.0218 12.7404C16.268 12.4943 16.4062 12.1604 16.4062 11.8123C16.4062 11.5528 16.3293 11.299 16.1851 11.0832C16.0408 10.8673 15.8359 10.6991 15.596 10.5998C15.3562 10.5004 15.0923 10.4744 14.8377 10.5251C14.5831 10.5757 14.3492 10.7007 14.1657 10.8843C13.9821 11.0678 13.8571 11.3017 13.8065 11.5563C13.7558 11.8109 13.7818 12.0748 13.8812 12.3146C13.9805 12.5544 14.1487 12.7594 14.3646 12.9037Z"
        stroke="transparent"
      />
    </g>
    <defs>
      <clipPath id="clip0_798_8231">
        <rect width="21" height="21" fill="transparent" stroke="transparent" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'arrow-left-small'"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_110_9440)">
      <path
        d="M12.3594 13.5416L15.8066 16.9888L14.8219 17.9735L10.39 13.5416L14.8219 9.10962L15.8066 10.0943L12.3594 13.5416Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_110_9440">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'arrow-select'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_726_6104)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.34088 8.87213C6.67038 8.54262 7.20462 8.54262 7.53412 8.87213L12 13.338L16.4659 8.87213C16.7954 8.54262 17.3296 8.54262 17.6591 8.87213C17.9886 9.20163 17.9886 9.73587 17.6591 10.0654L12.5966 15.1279C12.2671 15.4574 11.7329 15.4574 11.4034 15.1279L6.34088 10.0654C6.01137 9.73587 6.01137 9.20163 6.34088 8.87213Z"
        stroke="transparent"
      />
    </g>
    <defs>
      <clipPath id="clip0_726_6104">
        <rect
          width="18"
          height="18"
          transform="translate(3 3)"
          stroke="transparent"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'copy-lg'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_759_1842)">
      <path
        d="M15.1071 18H6.10714C5.33991 18 4.6041 17.6952 4.06158 17.1527C3.51907 16.6102 3.21429 15.8744 3.21429 15.1071V6.10714C3.21429 5.33991 3.51907 4.6041 4.06158 4.06158C4.6041 3.51907 5.33991 3.21429 6.10714 3.21429H15.1071C15.8744 3.21429 16.6102 3.51907 17.1527 4.06158C17.6952 4.6041 18 5.33991 18 6.10714V15.1071C18 15.8744 17.6952 16.6102 17.1527 17.1527C16.6102 17.6952 15.8744 18 15.1071 18Z"
        stroke="transparent"
      />
      <path
        d="M5.14286 1.92857H14.6202C14.4202 1.36496 14.0507 0.877047 13.5624 0.531758C13.0741 0.186469 12.4909 0.000722012 11.8929 0H2.89286C2.12562 0 1.38981 0.304782 0.847298 0.847298C0.304782 1.38981 0 2.12562 0 2.89286V11.8929C0.000722012 12.4909 0.186469 13.0741 0.531758 13.5624C0.877047 14.0507 1.36496 14.4202 1.92857 14.6202V5.14286C1.92857 4.29038 2.26722 3.47281 2.87001 2.87001C3.47281 2.26722 4.29038 1.92857 5.14286 1.92857Z"
        stroke="transparent"
      />
    </g>
    <defs>
      <clipPath id="clip0_759_1842">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'check-rounded'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5ZM8.25225 12.75L13.5548 7.44675L12.4943 6.38625L8.25225 10.629L6.1305 8.50725L5.07 9.56775L8.25225 12.75Z"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'ul-dot'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 12.1046 9.89543 13 11 13Z"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'ul-check'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 6.90763L9.02508 16L5 11.9846L5.97696 11.0099L8.95778 13.9836L15.9602 6L17 6.90763Z"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'ul-close'"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 7.16285L14.8372 6L11 9.83715L7.16285 6L6 7.16285L9.83715 11L6 14.8372L7.16285 16L11 12.1628L14.8372 16L16 14.8372L12.1628 11L16 7.16285Z"
      stroke="transparent"
    />
  </svg>

  <svg
    v-else-if="name === 'logout'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_843_3804)">
      <path
        d="M10.3077 12.2308V13.0769C10.3077 13.3217 10.2104 13.5565 10.0373 13.7296C9.86422 13.9027 9.62943 14 9.38462 14H2.92308C2.67826 14 2.44347 13.9027 2.27036 13.7296C2.09725 13.5565 2 13.3217 2 13.0769V2.92308C2 2.67826 2.09725 2.44347 2.27036 2.27036C2.44347 2.09725 2.67826 2 2.92308 2H9.38462C9.62943 2 9.86422 2.09725 10.0373 2.27036C10.2104 2.44347 10.3077 2.67826 10.3077 2.92308V3.76923"
        fill="transparent"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.53833 8H13.9999"
        fill="transparent"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1539 6.15381L14.0001 7.99996L12.1539 9.84612"
        fill="transparent"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_843_3804">
        <rect width="16" height="16" fill="transparent" stroke="transparent" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="name === 'person'"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C8.69223 8 9.36892 7.79473 9.9445 7.41015C10.5201 7.02556 10.9687 6.47894 11.2336 5.83939C11.4985 5.19985 11.5678 4.49612 11.4327 3.81719C11.2977 3.13825 10.9644 2.51461 10.4749 2.02513C9.98539 1.53564 9.36175 1.2023 8.68282 1.06725C8.00388 0.932205 7.30015 1.00152 6.66061 1.26642C6.02107 1.53133 5.47444 1.97993 5.08986 2.55551C4.70527 3.13108 4.5 3.80777 4.5 4.5C4.5 5.42826 4.86875 6.3185 5.52513 6.97488C6.1815 7.63125 7.07174 8 8 8ZM8 9C5.83063 9 1.5 10.34 1.5 13V15H14.5V13C14.5 10.34 10.1694 9 8 9Z"
      stroke="transparent"
    />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      default: null,
      type: String,
    },
  },
};
</script>
