<template>
  <div
    v-if="popup.name"
    class="fixed inset-0 flex items-center justify-center p-[30px] overflow-auto"
  >
    <!-- background -->
    <div
      v-if="popup.name"
      class="absolute inset-0 bg-color-text-main/50 z-[0]"
      @click="$setPopup(null)"
    />
    <!-- popup -->
    <component :is="popup.name" class="z-[1]" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    addMail: defineAsyncComponent(() => import("@/popups/addMail.vue")),
    confirmationMailSent: defineAsyncComponent(() =>
      import("@/popups/confirmationMailSent.vue")
    ),
    passwordMailSent: defineAsyncComponent(() =>
      import("@/popups/passwordMailSent.vue")
    ),
    withdrawal: defineAsyncComponent(() => import("@/popups/withdrawal.vue")),
  },
  computed: {
    ...mapGetters({ popup: "popups/popup" }),
  },
};
</script>

<style scoped lang="scss"></style>
