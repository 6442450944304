<template>
  <div
    class="input--search"
    :class="{
      disabled,
    }"
  >
    <input
      ref="input"
      class=""
      type="text"
      v-model="content"
      :disabled="disabled"
      :placeholder="placeholder"
      @keydown.enter="search"
    />

    <button v-if="button">
      <Icon :name="'search'" @click="search" />
    </button>
  </div>
</template>

<script>
import Icon from "../Icon.vue";

export default {
  components: { Icon },
  props: {
    placeholder: {
      default: "Поиск",
      type: String,
    },
    button: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    search() {
      this.$refs.input.focus();
      this.$emit("enter", this.content);
    },
  },
};
</script>

<style scoped lang="scss">
.input--search {
  @apply flex items-center justify-between gap-[5px] h-[26px] w-[200px] py-[5px] px-[10px] bg-white rounded-[5px] dark:bg-main-dark;

  & > input {
    @apply flex-[1_1_auto] text-sm bg-transparent;
  }

  & > button {
    @apply flex-[0_0_auto] fill-color-text-main stroke-color-text-main dark:stroke-white;
  }

  &.disabled {
    @apply cursor-default;

    & > input {
      @apply placeholder:text-inactive;
    }
  }
}
</style>
