<template>
  <button
    class="flex gap-[10px] px-[10px] py-[3px] rounded-[5px] text-md"
    :class="{
      'bg-back hover:bg-inactive active:bg-color-text-secondary active:text-main dark:bg-color-text-main dark:hover:bg-main dark:active:bg-color-text-main':
        !disabled && !active,
      'bg-inactive text-main cursor-default dark:bg-main dark:bg-main dark:text-white':
        !disabled && active,
      'bg-zebra text-color-text-secondary cursor-default dark:bg-second-dark dark:opacity-80': disabled,
    }"
    @click="filteredClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    filteredClick() {
      if (!this.disabled) {
        this.$emit("filteredClick");
      }
    },
  },
};
</script>
