<template>
  <div class="flex flex-col items-start gap-[10px]">
    <template v-for="(el, index) in elements">
      <InputLg
        v-if="el.component == 'input'"
        class="w-full"
        v-model:value="values[el.id]"
        :placeholder="getElTitle(el)"
        :readonly="el.readonly"
        :numbers="el.number"
        @enter="submit"
      />
      <BadgeGroup
        v-else-if="el.component == 'badges'"
        v-model:value="values[el.id]"
        :placeholder="getElTitle(el)"
        :items="el.settings"
      />
      <Checkbox
        v-else-if="el.component == 'checkbox'"
        v-model:value="values[el.id]"
        button
      >
        {{ getElTitle(el) }}
      </Checkbox>
    </template>
  </div>
</template>

<script>
import InputLg from "./input/lg.vue";
import BadgeGroup from "./badge/group.vue";
import Checkbox from "./Checkbox.vue";

export default {
  components: {
    InputLg,
    BadgeGroup,
    Checkbox,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["submit"],
  data() {
    return {
      values: this.setValues(),
    };
  },
  methods: {
    getElTitle(el) {
      return el.placeholder || el.id.replace("_", " ");
    },
    submit() {
      console.log(this.values)
      this.$emit("submit", this.values);
    },
    setValues(data = null) {
      this.values = {};
      this.elements.forEach((el) => {
        if (el.id) this.values[el.id] = data === null ? null : data[el.id];
      });

      return this.values;
    },
  },
};
</script>

<style scoped lang="scss"></style>
