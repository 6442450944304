<template>
  <div class="copy--rounded" @click="copy">
    <!-- header and text ot copy -->
    <div class="copy--rounded--content">
      <p v-if="title" class="copy--rounded--content__title">{{ title }}</p>
      <p class="copy--rounded--content__description">{{ text }}</p>
    </div>

    <!-- copy button -->
    <ButtonIconRounded :icon="'copy-lg'" :iconSize="'18px'" @click="copy" />

    <!-- "copied" badge -->
    <transition name="badge__animation">
      <div v-if="showBadge" class="copy--rounded--badge">
        <Icon class="copy--rounded--badge__icon" :name="'check-rounded'" />
        <p class="copy--rounded--badge__text">Ссылка скопирована</p>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from "../Icon.vue";
import ButtonIconRounded from "../button/iconRounded.vue";

export default {
  components: { Icon, ButtonIconRounded },
  props: {
    title: {
      default: null,
      type: String,
    },
    text: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      showBadge: false,
      showTimer: null,
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text);
      if (!this.showBadge) {
        this.openBadge();
      }
    },
    openBadge() {
      this.showBadge = true;

      if (this.showTimer != null) {
        clearTimeout(this.showTimer);
      }

      let closeNotificationTimer = setTimeout(this.closeBadge, 2500);
      this.showTimer = closeNotificationTimer;
      closeNotificationTimer;
    },
    closeBadge() {
      if (this.showTimer != null) {
        clearTimeout(this.showTimer);
      }
      this.showBadge = false;
    },
  },
};
</script>

<style scoped lang="scss">
.copy--rounded {
  @apply relative flex justify-between items-center gap-[14px] w-full h-[60px] px-[14px] bg-zebra border-[1px] border-solid border-inactive rounded-[8px] cursor-pointer select-none;

  &--content {
    @apply flex flex-col gap-[4px] justify-center w-full leading-[1em] overflow-hidden;

    &__title {
      @apply text-main-1 text-color-text-secondary-2 leading-[1em];
    }

    &__description {
      @apply text-lg text-main truncate;
    }
  }

  &--badge {
    @apply absolute right-[14px] flex items-center justify-center gap-[10px] h-[40px] px-[12px] bg-white rounded-[8px] opacity-[1];

    &__icon {
      @apply w-[18px] aspect-square fill-status-green;
    }

    &__text {
      @apply text-button;
    }
  }
}

.badge__animation-enter-active,
.badge__animation-leave-active {
  transition: all 1s ease;
}

.badge__animation-enter-from,
.badge__animation-leave-to {
  opacity: 0;
}

.badge__animation-enter-from {
  transform: translateX(200px);
}
</style>
