<template>
  <button class="button--icon" :class="{ disabled: disabled }" type="button">
    <Icon :name="icon" class="h-[16px]" />
  </button>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    icon: String,
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Icon: defineAsyncComponent(() => import("../Icon.vue")),
  },
};
</script>

<style scoped lang="scss">
.button--icon {
  @apply flex items-center justify-center h-[28px] w-[28px] rounded-[8px] duration-[0.2s];

  &:not(.disabled) {
    @apply bg-transparent fill-color-text-main hover:bg-icons/40 active:bg-icons/60;
  }

  &.disabled {
    @apply bg-transparent fill-color-text-secondary;
  }
}
</style>
