<template>
  <div
    class="flex-[0_1_auto] flex gap-[10px] items-center text-sm-1 cursor-pointer"
    @click="$emit('update:value', !value)"
  >
    <div
      class="flex items-center w-[24px] h-[14px] rounded-[14px]"
      :class="{ 'bg-icons': value == false, 'bg-main': value == true }"
    >
      <div
        class="w-[10px] h-[10px] bg-white rounded-full"
        :class="{ 'ml-[2px]': value == false, 'ml-[12px]': value == true }"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  emits: ["update:value"],
};
</script>
