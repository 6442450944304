<template>
  <ButtonTemplate
    class="py-[10px] px-[20px] text-button"
    :disabled="disabled"
    :color="color"
    :submit="submit"
  >
    <slot />
  </ButtonTemplate>
</template>

<script>
import { ButtonTemplate } from "ui";

export default {
  components: { ButtonTemplate },
  props: {
    color: {
      default: "main",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    submit: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
