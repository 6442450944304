import { format } from "date-fns";

export default function (date, time = false, between = " / ") {
  let result = null;
  if (date) {
    var formatDateView_1 = "dd.MM.yyyy";
    var formatDateView_2 = `hh:mm`;
    var formatTextView = `dd.MM.yyyy${time ? ", hh:mm" : " "}`;
  } else {
    var formatDateView = "dd.MM.yyyy";
    var formatTextView = "dd.MM.yyyy";
  }

  let formattedToDate = (dateToFormat) =>
    `${format(dateToFormat, formatDateView_1, {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    })}${
      time
        ? "T" +
          format(dateToFormat, formatDateView_2, {
            useAdditionalDayOfYearTokens: true,
            useAdditionalWeekYearTokens: true,
          })
        : ""
    }`;
  let formattedToText = (dateToFormat) =>
    `${format(dateToFormat, formatTextView)}`;

  if (date) {
    if (date.length && date[0] && date[1]) {
      result = {
        data: `${formattedToDate(date[0])} / ${formattedToDate(date[1])}`,
        text: `${formattedToText(date[0])}${between}${formattedToText(
          date[1]
        )}`,
        raw: date,
      };
    } else if (!date.length) {
      result = {
        date: `${formattedToDate(date)}`,
        text: `${formattedToText(date)}`,
        raw: date,
      };
    }
  }
  return result;
}
