<template>
  <router-link
    class="relative flex items-center justify-center p-[5px] pt-[0px] text-color-text-secondary text-main-1 hover:text-main active:text-color-text-secondary"
    active-class="text-main border-b-[1px] border-solid border-main cursor-default active:text-main"
    :to="to"
  >
    <span v-if="text">{{ text }}</span>
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    text: {
      default: null,
      type: String,
    },
    to: {
      default: "/",
      type: String,
    },
  },
};
</script>
