import { axios } from "global-plugins";
import router from "@/router/index.js";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const state = () => ({
  user: null,
  ref_user: null,
});

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_REF_USER(state, user) {
    state.ref_user = user;
  },
};

const actions = {
  login({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      let user_data;
      await axios
        .post("/users/login/", payload)
        .then((resp) => {
          commit("SET_USER", resp.data);
          axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");
          axios.defaults.withCredentials = true;
          user_data = resp.data;
        })
        .catch((err) => {
          reject(err);
        });

      dispatch("get_ref")
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  },
  register({ commit, dispatch }, payload) {
    let user_data;

    return new Promise(async (resolve, reject) => {
      await axios
        .post("/users/create/", payload)
        .then((resp) => {
          commit("SET_USER", resp.data);

          axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");
          axios.defaults.withCredentials = true;

          user_data = resp.data;
        })
        .catch((err) => {
          reject(err);
        });

      dispatch("get_ref")
        .then((resp) => {
          resolve(user_data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  },
  get_me({ commit, dispatch }) {
    return new Promise(async (resolve, reject) => {
      let user_data;
      await axios
        .get("/users/get_me/")
        .then((resp) => {
          commit("SET_USER", resp.data);
          axios.defaults.withCredentials = true;
          axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");
          user_data = resp.data;
        })
        .catch((err) => {
          reject(err);
        });

      dispatch("get_ref")
        .then((resp) => {
          resolve(user_data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  },
  get_ref({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/referal_program/ref2_user_info/")
        .then((resp) => {
          commit("SET_REF_USER", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/users/logout/")
        .then((resp) => {
          commit("SET_USER", null);
          axios.defaults.headers.common["X-CSRFToken"] = null;
          axios.defaults.withCredentials = true;
          router.push("/login/");
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  change_password({}, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/users/recover/", {
          email: payload,
          is_partner_cab: 1,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

const getters = {
  user: (state) => state.user,
  ref_user: (state) => state.ref_user,
  isLoggedIn: (state) => (state.user ? true : false),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
