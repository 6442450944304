<template>
  <div class="flex items-center gap-[10px]">
    <p class="text-button">Показать</p>
    <div class="flex gap-[10px]">
      <ButtonSquare
        v-for="(button, index) in pageSizeButtons"
        :key="index"
        :active="button == pageSize"
        @click="changePageSize(button)"
      >
        {{ button }}
      </ButtonSquare>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ButtonSquare from "../button/square.vue";

export default {
  components: {
    ButtonSquare,
  },
  data() {
    return {
      pageSizeButtons: [50, 100, 200],
    };
  },
  computed: {
    ...mapGetters({
      pageSize: "tables/pageSize",
    }),
  },
  methods: {
    ...mapMutations({ setPageSize: "tables/SET_PAGE_SIZE" }),
    changePageSize(value) {
      this.setPageSize(value);
      this.$emit("changePageSize", value);
    },
  },
};
</script>
