// Add "title" meta to page in router/index.js

export default {
  install: (app, options) => {
    // console.log(app.config.globalProperties.$route);
    let vueRouter = app.config.globalProperties.$router;
    vueRouter.afterEach((next, prev) => {
      document.title = next.meta.title
        ? next.meta.title
        : (options || "Загрузка...");
    });
  },
};
