<template>
  <button
    class="relative flex items-center justify-center w-[25px] aspect-square rounded-[5px] text-button"
    :class="{
      'bg-white dark:bg-back-dark dark:hover:bg-main-dark dark:active:bg-back-dark': !active,
      'bg-back-dark text-white cursor-default dark:bg-main-dark': active,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    active: {
      default: false,
      type: Boolean,
    }
  }
};
</script>
