<template>
  <div class="textfield--rounded">
    <!-- title -->
    <p v-if="title" class="textfield--rounded__title">{{ title }}</p>
    <!-- textfield -->
    <div class="textfield--rounded--container">
      <p class="textfield--rounded--container__text">
        <slot name="text" />
      </p>
      <slot name="menu" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.textfield--rounded {
  @apply flex flex-col gap-[4px] w-full;

  &__title {
    @apply text-main-1 leading-[24px];
  }

  &--container {
    @apply flex items-center gap-[12px] w-full h-[48px] p-[12px] pl-[14px] bg-zebra border-[1px] border-solid border-color-stroke rounded-[8px];

    &__text {
      @apply flex-[1_1_auto] text-main-1 truncate;
    }
  }
}
</style>
