import { axios } from "global-plugins";

const state = () => ({
  currencies: [],
  currenciesFilter: {
    items: [],
    name: "Валюта",
    ref: "currency",
  },
  providers: [],
  providersFilter: {
    items: [],
    name: "Провайдеры",
    ref: "provider",
  }
})

const mutations = {
  SET_CURRENCIES(state, payload) {
    let filterResult = {
      items: [],
      name: "Валюта",
      ref: "currency",
    }
    payload.forEach((el) => {
      filterResult.items.push({
        text: el.name,
        value: el.id,
      });
    });
    state.currenciesFilter = filterResult
    state.currencies = payload
  },
  SET_PROVIDERS(state, payload) {
    let filterResult = {
      items: [],
      name: "Провайдеры",
      ref: "provider",
    }
    payload.forEach((el) => {
      filterResult.items.push({
        text: el.name,
        value: el.id,
      });
    });
    state.providersFilter = filterResult
    state.providers = payload
  },
}

const actions = {
  loadCurrencies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/currencies/").then(resp => {
        commit("SET_CURRENCIES", resp.data.results)
        resolve(resp.data.results)
      }).catch(err => {
        reject(err)
      })
    })
  },
  loadProviders({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/games/providers/").then(resp => {
        commit("SET_PROVIDERS", resp.data.results)
        resolve(resp.data.results)
      }).catch(err => {
        reject(err)
      })
    })
  },
}

const getters = {
  currencies: (state) => state.currencies,
  currenciesFilter: (state) => state.currenciesFilter,
  providers: (state) => state.providers,
  providersFilter: (state) => state.providersFilter,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}