<template>
  <Datepicker
    v-model="date"
    inline
    auto-apply
    :action-row="{ showPreview: false, showSelect: true, showCancel: true }"
    :range="pickRange"
    :multiCalendars="!time && screenSize.width > 750"
    :enable-time-picker="time"
    :selectText="'Выбрать'"
    :presetRanges="pickRange && screenSize.width > 500 ? presetRanges : false"
    no-swipe
    @update:modelValue="showPicker = false"
  >
    <template #yearly="{ label, range, presetDateRange }">
      <span @click="presetDateRange(range)">{{ label }}</span>
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
import { ref } from "vue";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
} from "date-fns";

export default {
  components: { Datepicker },
  props: {
    time: {
      default: false,
      type: Boolean,
    },
    pickRange: {
      default: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    const date = ref([startOfMonth(new Date()), endOfMonth(new Date())]);

    const presetRanges = ref([
      { label: "Сегодня", range: [new Date(), new Date()] },
      {
        label: "Вчера",
        range: [startOfYesterday(new Date()), endOfYesterday(new Date())],
      },
      {
        label: "Эта неделя",
        range: [startOfWeek(new Date()), endOfWeek(new Date())],
      },
      {
        label: "Этот месяц",
        range: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
      {
        label: "Предыдущий месяц",
        range: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ],
      },
      {
        label: "Этот год",
        range: [startOfYear(new Date()), endOfYear(new Date())],
      },
    ]);

    const handleDate = (modelData) => {
      date.value = modelData;
    };

    return {
      date,
      presetRanges,
      handleDate,
    };
  },
  computed: {
    screenSize() {
      return {
        width: window.screen.width,
        height: window.screen.height,
      };
    },
  },
  mounted() {
    window.__localeId__ = "enGB";
  },
  watch: {
    date: {
      handler(newValue, oldValue) {
        this.$emit("update", this.$formatDate(newValue, this.time));
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "//vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #e6e6e6;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #264fe1;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #e6e6e6;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #929292;
  --dp-scroll-bar-color: #e6e6e6;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}

.dp__main {
  @apply border-[0px] rounded-[5px];
}

.dp__instance_calendar > .dp__flex_display {
  @apply pl-[30px] pt-[15px] pb-[15px] pr-[40px];
}

.dp__menu {
  border: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.dp__preset_ranges {
  @apply flex flex-col gap-[5px] py-[20px] pl-[15px] pr-[5px] w-[155px];
}

.dp__preset_range {
  @apply flex items-center gap-[5px] text-button h-[25px] w-[135px] p-[5px];
  border-radius: 5px;
  white-space: nowrap;
}

.dp__action {
  @apply bg-color-text-main px-[15px] py-[6.5px] hover:bg-main active:bg-color-text-main text-white font-medium;
  font-weight: 400 !important;

  &_row {
    @apply justify-end w-auto pt-[15px] pr-[40px] pb-[20px] ml-[154px] border-l-[1px] border-solid border-inactive;
  }

  &_buttons {
    width: auto !important;
  }
}

.dp__calendar_row {
  @apply border-[0px] rounded-[5px] overflow-hidden;
}

.dp__cell {
  &_inner {
    @apply flex items-center justify-center w-[28px] h-[28px] text-button;
  }
}

.dp__today {
  @apply border-[0px];

  &:not(.dp__range_start, .dp__range_end) {
    @apply text-main;
  }
}

.dp__select {
  @apply h-auto w-auto px-[10px] py-[6.5px] text-button;
}

.dp__selection {
  &_preview {
    @apply hidden;
  }
}

.dp__calendar {
  &_header {
    &_item {
      @apply flex items-center justify-center w-[28px] h-[28px] text-button;
    }

    &_separator {
      @apply hidden;
    }
  }
}

.dp__month_year_select {
  @apply text-md;
}

.dp__inner_nav {
  @apply rounded-[5px];

  & > svg {
    @apply fill-color-text-main;
  }
}
</style>
