<template>
  <div class="flex items-center pl-[5px] z-[10] disable-click-outside">
    <div
      class="absolute left-0 bg-color-text-main h-[9.6px] aspect-square z-[1] rotate-[45deg]"
    />
    <div
      class="flex items-center h-[25px] px-[10px] bg-color-text-main rounded-[6px] z-[2]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="postcss"></style>
