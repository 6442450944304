<template>
  <div
    class="flex items-center justify-center h-[25px] w-[110px] px-[8px] rounded-[5px] text-button font-normal disable-click-outside"
    :class="color"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      default: "green",
      type: String,
    },
  },
};
</script>

<style scoped lang="postcss">
.red {
  @apply bg-status-light-red text-status-red dark:bg-[rgba(240,_36,_37,_0.3)];
}
.green {
  @apply bg-status-light-green text-status-green dark:bg-[rgba(36,_165,_25,_0.3)];
}
.blue {
  @apply bg-status-light-blue text-status-blue dark:bg-[rgba(32,_106,_255,_0.3)];
}
.purple {
  @apply bg-status-light-purple text-status-purple dark:bg-[rgba(139,_30,_212,_0.3)];
}
.pink {
  @apply bg-status-light-pink text-status-pink dark:bg-[rgba(225,_10,_204,_0.3)];
}
.select {
  @apply bg-back cursor-pointer hover:bg-inactive active:bg-color-text-secondary active:text-main dark:bg-color-text-main dark:hover:bg-main dark:active:bg-color-text-main;
}
</style>
