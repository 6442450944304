<template>
  <div v-if="!(pagesCount <= 1 && pageSize == 50)" class="table--footer">
    <TablePageSize />
    <Pagination :pages="pagesCount" @changePage="changePage" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Pagination from "../Pagination.vue";
import TablePageSize from "../table/pageSize.vue";

export default {
  components: {
    Pagination,
    TablePageSize,
  },
  computed: {
    ...mapGetters({
      page: "tables/page",
      pageSize: "tables/pageSize",
      pagesCount: "tables/pagesCount",
    }),
  },
  methods: {
    ...mapMutations({ setPage: "tables/SET_PAGE", setPageSize: "tables/SET_PAGE_SIZE" }),
    changePageSize(value) {
      this.setPageSize(value);
    },
    changePage(value) {
      this.setPage(value);
    },
  },
  mounted() {
    this.setPageSize(50);
  },
};
</script>

<style scoped lang="scss">
.table--footer {
  @apply flex justify-between w-full;
}
</style>
