import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
// Global plugins
import { getErrorMessage, popups as popupsPlugin } from "global-plugins";
// Local plugins
import {
  axios,
  filterClickOutside,
  formatDate,
  pageTitle,
} from "global-plugins";
import VueAxios from "vue-axios";
import router from "./router";
import store from "./store";
import vClickOutside from "click-outside-vue3";
//Mixins
// import pageTitleMixins from './mixins/pageTitle'
// Styles
import "ui/css/main.scss";
import "@/assets/additional.scss";
// Components
import AccountModeratingComponent from "@/components/AccountModerating.vue";

// localStorage methods
Storage.prototype.setObj = function (key, obj) {
  return this.setItem(key, JSON.stringify(obj));
};
Storage.prototype.getObj = function (key) {
  return JSON.parse(this.getItem(key));
};

let app = createApp(App);

app.config.globalProperties.filterClickOutside = filterClickOutside;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$getError = getErrorMessage;

app
  .component("AccountModerating", AccountModeratingComponent)
  .use(store)
  .use(router)
  .use(pageTitle, "Реферальный кабинет")
  .use(VueAxios, axios)
  .use(vClickOutside)
  .use(popupsPlugin)
  .mount("#app");
