const regexes = [
  {
    example: "2200700952714444",
    function: (text) => {
      let new_text = text.substring(0, 16);
      new_text = new_text.match(/.{1,4}/g).join(" ");

      return new_text;
    },
  },
  {
    example: "TX6kjGqpia9oZqfokforLerJG8CcydWH3W",
    placeholder: "TX6kjGqpia9oZqfokforLerJG8CcydWH3W",
    function: (text) => {
      return text;
    },
  },
  {
    example: "35HkyUQg3o957bknhBcQhRuQpmsT5uK1RJ",
    placeholder: "35HkyUQg3o957bknhBcQhRuQpmsT5uK1RJ",
    function: (text) => {
      return text;
    },
  },
];

const ConvertInput = function (text, regexp = null) {
  let clearText = text.split(" ").join("");

  const matchedRegex = regexp
    ? regexes.find((reg) => regexp.test(reg.example))
    : undefined;

  if (
    ["", " ", null, undefined].includes(clearText) ||
    !regexp ||
    matchedRegex === undefined
  )
    return {
      text: text,
      placeholder: matchedRegex
        ? matchedRegex.function(matchedRegex.example)
        : matchedRegex.example,
    };

  return {
    text: matchedRegex.function(clearText),
    placeholder: matchedRegex
      ? matchedRegex.function(matchedRegex.example)
      : matchedRegex.example,
  };
};

export default ConvertInput;
