<template>
  <div class="flex flex-col gap-[8px] max-h-[80vh]">
    <Warning
      v-for="(warning, index) in warnings"
      :key="index"
      :title="warning.title"
      :color="warning.status"
    >
      {{ warning.text }}
    </Warning>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { defineAsyncComponent } from "vue";

export default {
  components: {
    Warning: defineAsyncComponent(() => import("../Warning.vue")),
  },
  computed: {
    ...mapGetters({ warnings: "warnings/warnings" }),
  },
  methods: {
    ...mapMutations({ createWarning: "warnings/CREATE_WARNING" }),
  },
};
</script>

<style scoped lang="scss"></style>
