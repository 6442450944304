<template>
  <div
    class="flex-[0_0_auto] flex flex-nowrap gap-[15px] w-full my-[15px] px-[15px] overflow-x-scroll"
  >
    <LinkUnderline
      v-for="(link, index) in links"
      :key="index"
      :text="link.text"
      :to="link.to"
      class="flex-[0_0_auto]"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    LinkUnderline: defineAsyncComponent(() => import("./link/underline.vue")),
  },
  props: {
    links: {
      default: () => ({}),
      type: Object,
    },
  },
};
</script>

<style></style>
