<template>
  <div class="badge--primary disable-click-outside" :class="{ active, disabled }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.badge--primary {
  @apply flex items-center justify-center w-[70px] h-[30px] rounded-[6px] bg-back border-[1px] border-solid border-transparent text-button cursor-pointer dark:bg-second-dark dark:text-white;

  box-sizing: border-box;

  &:not(.active, .disabled) {
    &:hover {
      @apply bg-inactive dark:bg-main-dark;
    }

    &:active {
      @apply bg-icons dark:bg-main-dark dark:opacity-80;
    }
  }

  &.active {
    @apply border-main bg-status-light-blue text-color-main cursor-default dark:bg-main-dark dark:border-status-blue;
  }

  &.disabled {
    @apply bg-back text-color-text-secondary cursor-default dark:bg-second-dark dark:opacity-80;
  }
}
</style>
