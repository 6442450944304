<template>
  <div class="relative pt-[12px]">
    <textarea
      class="resize-none w-full w-full pt-[5px] pb-[7px] bg-transparent border-b-[1px] border-solid border-color-text-secondary text-color-text-main text-main-1 dark:border-color-text-hover dark:text-white"
      :class="{
        'border-b-status-red text-status-red': wrong,
      }"
      :type="hidden"
      :value="value"
      ref="textarea"
      rows="1"
      placeholder=""
      required
      @focus="resize"
      @keyup="resize"
      @input="$emit('update:value', $event.target.value)"
    />

    <!-- Floating placeholder -->
    <span class="floating-label text-color-text-secondary">{{ placeholder }}</span>

    <!-- Warning text -->
    <p v-if="wrong" class="text-sm text-status-red mt-[5px]">{{ warningText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    hide: {
      default: false,
      type: Boolean,
    },
    warningText: {
      default: "Неверные данные",
      type: String,
    },
    placeholder: {
      default: "Вводить сюда",
      type: String,
    },
  },
  emits: ["update:value"],
  data() {
    return {
      wrong: false,
      hidden: false,
    };
  },
  computed: {
    content() {
      return this.value;
    },
  },
  methods: {
    changeStatus(state) {
      this.wrong = state;
    },
    resize() {
      const { textarea } = this.$refs;
      textarea.style.height = textarea.scrollHeight - 4 + "px";
    },
  },
  mounted() {
    this.hidden = this.hide;
    this.content = this.value;
    setTimeout(() => {
      this.resize();
    }, 1);
  },
};
</script>

<style scoped lang="scss">
textarea:focus ~ .floating-label,
textarea:not(:focus):valid ~ .floating-label {
  top: 2px;
  left: 0;
  font-size: 10px;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 2px;
  top: 15px;
  font-size: 14px;
}
</style>
