<template>
  <transition name="transition-warning">
    <div
      v-if="isShown"
      class="warning--class relative flex flex-col w-[300px] p-[12px] bg-white border-l-[8px] border-solid rounded-[8px] cursor-pointer duration-[0.3s] hover:opacity-70 hover:ml-[10px] dark:bg-main-dark dark:text-white"
      :class="[`color-${color}`]"
      @click="isShown = false"
    >
      <p v-if="title" class="text-main-1 font-semibold">{{ title }}</p>
      <div class="text-md">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      default: null,
      type: String,
    },
    color: {
      default: "success",
      type: String,
    },
  },
  data() {
    return {
      isShown: false,
    };
  },
  methods: {},
  mounted() {
    this.isShown = true;
    setTimeout(() => {
      this.isShown = false
    }, 12000)
  },
};
</script>

<style scoped lang="scss">
.warning--class {
  box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04), 0px 8px 24px rgba(0, 32, 51, 0.12);
}

.color- {
  &success {
    @apply border-request-success;
  }
  &error {
    @apply border-request-error;
  }
  &system {
    @apply border-request-system;
  }
}

.transition-warning-enter-active,
.transition-warning-leave-active {
  transition: 0.6s ease-in-out;
  opacity: 1;
  right: 0;
}

.transition-warning-enter-from,
.transition-warning-leave-to {
  opacity: 0;
  right: 200px;
}
</style>
