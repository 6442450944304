<template>
  <!-- filters -->
  <div class="table--default flex justify-between gap-[5px] w-full z-[1]">
    <div class="flex gap-[5px]">
      <InputSearch
        v-if="search && searchPosition === 'left'"
        @enter="
          (data) => {
            searchContent = data;
            makeRequest();
          }
        "
      />
      <template v-for="(dropdown, index) in filtersData" :key="index">
        <DropdownRadio
          v-if="!dropdown.multiple"
          :title="dropdown.name"
          :elements="dropdown.dropdown"
          @choosed="
            (data) => {
              filtersData[index].choosed = data.index;
              makeRequest();
            }
          "
          @clear="
            (data) => {
              filtersData[index].choosed = null;
              makeRequest();
            }
          "
        />
        <DropdownCheckbox
          v-else
          :title="dropdown.name"
          :elements="dropdown.dropdown"
          @apply="
            (data) => {
              appliedFilter(data, index);
            }
          "
          @closed="
            (data) => {
              appliedFilter(data, index);
            }
          "
        />
      </template>
      <slot name="menu-left" />
    </div>
    <div class="flex gap-[5px]">
      <slot name="menu-right" />
      <InputSearch
        v-if="search && searchPosition === 'right'"
        @enter="
          (data) => {
            searchContent = data;
            makeRequest();
          }
        "
      />
      <slot name="menu-right-1" />
      <DatePickerComponent
        v-if="datePicker"
        @update="changeDate"
      />
      <slot name="menu-right-2" />
    </div>
  </div>
  <div
    class="table--lg z-[0]"
    :class="{
      'borders-outside': borders?.outside,
      'borders-header': borders?.header,
      'borders-between': borders?.between,
    }"
  >
    <table>
      <TableHeader
        v-if="headers?.length"
        :elements="headers"
        :checkbox="checkbox"
        @checkbox="$emit('checkbox')"
        @toggle="changeOrdering"
        @setHeaderValue="
          (data) => {
            headers[data.id].component.value = data.value;
            makeRequest();
          }
        "
        class="z-[1]"
      />
      <tbody class="table--content z-[0]">
        <slot name="table" />
      </tbody>
    </table>
    <slot name="table-footer" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { defineAsyncComponent } from "vue";
import DatePickerComponent from "../DatePicker.vue";

export default {
  components: {
    TableHeader: defineAsyncComponent(() => import("../table/header.vue")),
    DropdownRadio: defineAsyncComponent(() => import("../dropdown/radio.vue")),
    DropdownCheckbox: defineAsyncComponent(() =>
      import("../dropdown/checkbox.vue")
    ),
    InputSearch: defineAsyncComponent(() => import("../input/search.vue")),
    DatePickerComponent,
  },
  props: {
    allow: {
      default: true,
      type: Boolean,
    },
    checkbox: {
      default: null,
      type: Boolean,
    },
    url: {
      default: null,
      type: String,
    },
    search: {
      defualt: false,
      type: Boolean,
    },
    searchPosition: {
      default: "right",
      type: String,
    },
    headers: {
      default: [],
      type: Array,
    },
    filters: {
      default: [],
      type: Array,
    },
    datePicker: {
      defualt: false,
      type: Boolean,
    },
    borders: {
      default: null,
      type: Object,
    },
    defaultOrdering: {
      default: "-id",
      type: String,
    },
    useOrdering: {
      default: true,
      type: Boolean,
    },
    staticFilters: {
      default: "",
      type: String,
    },
    noPages: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["checkbox", "response"],
  data() {
    return {
      ordering: null,
      searchContent: null,
      filtersData: [],
      date: null,
    };
  },
  computed: {
    ...mapGetters({ page: "tables/page", pageSize: "tables/pageSize" }),
  },
  methods: {
    ...mapMutations({
      setPagesCount: "tables/SET_PAGES_COUNT",
      setPage: "tables/SET_PAGE",
    }),
    makeRequest(customFilters = {}) {
      if (this.url !== null) {
        let requestLink = `${this.url}?page=${this.page}&page_size=${this.pageSize}${this.staticFilters}`;
        // Set ordering of request
        if (this.useOrdering) {
          requestLink += `&ordering=${
            this.ordering !== null ? this.ordering : this.defaultOrdering
          }`;
        }
        // Set filters of request
        this.filtersData.forEach((filter) => {
          if (filter.choosed != null)
            requestLink += `&${filter.ref}=${
              filter.multiple ? filter.choosed : filter.values[filter.choosed]
            }`;
        });
        // Set custom filters of request
        Object.entries(customFilters).forEach((entry) => {
          const [key, value] = entry;
          requestLink += `&${key}=${value}`;
        });
        // Other request params
        if (this.searchContent) requestLink += `&search=${this.searchContent}`;
        if (this.date) requestLink += `&date_range=${this.date}`;
        this.headers.forEach((el) => {
          if (
            el.component?.value !== null &&
            el.component?.value !== undefined
          ) {
            requestLink += `&${el.component.ref}=${el.component.value}`;
          }
        });
        // Make request
        this.axios.get(requestLink).then((resp) => {
          this.setPagesCount(resp.data.count);
          this.$emit("response", resp.data.results);
        });
      }
    },
    addFilter(data) {
      let newFilter = {
        dropdown: [],
        values: [],
        name: data.name,
        ref: data.ref,
        multiple: data.multiple,
        choosed: null,
      };
      data.items.forEach((el) => {
        if (!data.multiple) {
          newFilter.dropdown.push(el.text);
        } else {
          newFilter.dropdown.push({
            text: el.text,
            value: el.value,
            checked: false,
          });
        }
        newFilter.values.push(el.value);
      });
      this.filtersData.push(newFilter);
    },
    changeOrdering(value) {
      this.ordering = value;
      this.setPage(1);
    },
    changeDate(data) {
      this.date = data.data;
      this.makeRequest();
    },
    appliedFilter(filterResults, filterId) {
      let filterArray = [];
      filterResults.forEach((el) => {
        if (el.checked) {
          filterArray.push(el.value);
        }
      });
      console.log(filterArray.join(","));

      this.filtersData[filterId].choosed = filterArray.join(",");
      this.makeRequest();
    },
  },
  mounted() {
    this.filters.forEach((filter) => {
      this.addFilter(filter);
    });
    this.makeRequest();
  },
  watch: {
    // filters: {
    //   handler(val) {
    //     if (this.page !== 1) {
    //       this.setPage(1);
    //     } else this.makeRequest();
    //   },
    //   deep: true,
    // },
    ordering() {
      if (this.page !== 1) {
        this.setPage(1);
      } else this.makeRequest();
    },
    page() {
      this.makeRequest();
    },
    pageSize() {
      this.makeRequest();
    },
  },
};
</script>

<style lang="scss">
.table--lg {
  @apply relative w-full overflow-x-auto overflow-y-visible rounded-[5px] bg-white dark:bg-second-dark;

  & > table {
    @apply w-full border-separate border-spacing-x-[0px] border-spacing-y-[4px];

    & > * {
      &:first-child {
        border-radius: 5px 5px 0 0;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    & td,
    & th {
      @apply px-[15px];
    }
  }

  &.borders {
    &-outside {
      @apply border-[1px] border-solid border-inactive;
    }

    &-header {
      & th {
        @apply pb-[10px] border-b-[1px] border-solid border-inactive;
      }
    }

    &-between {
      & > table {
        border-collapse: collapse;
        border-spacing: 0;

        & td,
        & th {
          @apply px-[15px] border-[1px] border-solid border-inactive;
        }
      }
    }
  }

  & tr {
    @apply relative text-sm z-[1] cursor-default whitespace-nowrap;

    &::after {
      content: "" !important;
    }

    & > td {
      @apply py-[10px] align-middle;

      &:first-child {
        @apply pl-[15px];
      }

      &:last-child {
        @apply pr-[10px];
      }
    }
  }

  &:not(.borders-between) {
    & tr:not(.table--header--row) {
      @apply border-transparent border-solid border-l-[5px] border-r-[5px];

      &::after {
        @apply absolute inset-0 rounded-[3px] z-[-1];
      }

      &:nth-child(odd)::after {
        @apply bg-zebra dark:bg-main-dark;
      }

      &:not(.no-selection) {
        @apply cursor-pointer;

        &:not(.active):hover::after {
          box-shadow: 0px 0px 35px rgba(109, 120, 150, 0.15);
        }

        &:not(.active):active::after {
          box-shadow: none;
        }
      }

      &.active {
        &::after {
          @apply bg-status-light-blue border-l-[5px] border-l-status-blue dark:bg-status-blue dark:bg-opacity-30 dark:hover:bg-status-blue;
        }
      }
    }
  }
}

.table {
  &--content {
    @apply w-full h-full border-solid;
  }
}
</style>
