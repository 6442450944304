<template>
  <button class="badge--primary">
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.badge--primary {
  @apply flex-[0_0_auto] bg-status-blue/10 h-[16px] px-[6px] rounded-[4px] text-[9px] leading-[11px] font-normal;

  &:not(.disabled) {
    @apply opacity-90 text-main;

    &:hover,
    &:active {
      @apply opacity-100 text-status-blue;
    }
  }

  &.disabled {
    @apply cursor-default text-main;
  }
}
</style>
