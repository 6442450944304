<template>
  <div class="page--table flex flex-col gap-[15px] w-full pb-[15px]">
    <!-- <PagesLinks v-if="links.length" :links="this.$route.links" /> -->
    <div
      class="page--table--content flex flex-col gap-[15px] px-[15px]"
      :class="{
        'mt-[15px]': !$route.meta.links,
      }"
    >
      <slot />
    </div>
    <TableFooter class="p-[15px] pb-[0px]" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import BadgePrimary from "./badge/primary.vue";
import LinkUnderline from "./link/underline.vue";

export default {
  components: {
    BadgePrimary,
    LinkUnderline,
    TableFooter: defineAsyncComponent(() => import("./table/footer.vue")),
    PagesLinks: defineAsyncComponent(() => import("./PagesLinks.vue")),
  },
  props: {
    // links: {
    //   default: [],
    //   type: Array,
    // },
  },
};
</script>
