import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/login.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Вход",
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/auth/register.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Регистрация",
    },
  },
  {
    path: "/set-communication",
    name: "set-communication",
    component: () => import("@/pages/auth/set-communication.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Способ связи",
    },
  },
  {
    path: "/restore-password",
    name: "restore-password",
    component: () => import("@/pages/auth/restore-password.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Восстановление пароля",
    },
  },
  {
    path: "/email-confirm/:confirm_token",
    name: "email-confirm",
    component: () => import("@/pages/auth/email-confirm.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Подтверждение почты",
    },
  },
  {
    path: "/change-password/:restore_token",
    name: "change-password",
    component: () => import("@/pages/auth/change-password.vue"),
    meta: {
      layout: "LayoutAuth",
      title: "Смена пароль",
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("@/pages/account.vue"),
    meta: {
      title: "Профиль",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/pages/dashboard.vue"),
    meta: {
      title: "Дашборд",
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () => import("@/pages/statistics.vue"),
    meta: {
      title: "Статистика",
    },
  },
  {
    path: "/finances",
    redirect: "/finances/deposits",
    name: "finances",
    component: () => import("@/pages/finances/index.vue"),
    meta: {
      hideLoad: true,
      title: "Финансы",
    },
    children: [
      {
        path: "deposits",
        name: "finances_deposits",
        component: () => import("@/pages/finances/deposits.vue"),
      },
      {
        path: "withdraws",
        name: "finances_withdraws",
        component: () => import("@/pages/finances/withdraws.vue"),
      },
    ],
  },
  {
    path: "/referals",
    redirect: "/referals/deposits",
    name: "referals",
    component: () => import("@/pages/referals/index.vue"),
    meta: {
      hideLoad: true,
      title: "Рефералы",
    },
    children: [
      {
        path: "deposits",
        name: "referals_deposits",
        component: () => import("@/pages/referals/deposits.vue"),
      },
      {
        path: "withdraws",
        name: "referals_withdraws",
        component: () => import("@/pages/referals/withdraws.vue"),
      },
    ],
  },
  {
    path: "/referal-link",
    name: "referal-link",
    component: () => import("@/pages/referal-link.vue"),
    meta: {
      title: "Партнерская ссылка",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// function findMatch(array, match) {
//   let result = false;

//   array.forEach((arr) => (result = arr.path === match ? true : result));

//   return result;
// }

const allowedAuthPages = ["change-password"];

router.beforeEach(async function (to, from) {
  let authResult;
  store.commit("pages/SET_PAGE_LOADING", true);

  store.commit(
    "pages/SET_HIDE_LOAD",
    from.meta?.hideLoad && to.meta?.hideLoad ? true : false
  );

  await store
    .dispatch("auth/get_me")
    .then(async (resp) => {
      if (to.matched.some((record) => record.meta.layout === "LayoutAuth" && !allowedAuthPages.includes(to.name))) {
        authResult = { path: "/dashboard" };
      } else authResult = true;
    })
    .catch((err) => {
      if (to.matched.some((record) => record.meta.layout !== "LayoutAuth")) {
        authResult = { path: "/login" };
      } else authResult = true;
    });
  store.commit("pages/SET_PAGE_LOADING", false);

  return authResult;
});

export default router;
