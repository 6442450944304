<template>
  <ButtonTemplate
    class="py-[5px] px-[10px]"
    :disabled="disabled"
    :color="color"
    :submit="submit"
  >
    <slot />
  </ButtonTemplate>
</template>

<script>
import ButtonTemplate from "./template.vue";

export default {
  components: { ButtonTemplate },
  props: {
    color: {
      default: "white",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    submit: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
