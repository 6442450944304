const state = () => ({
  trigger: false,
  rows: [],
});

const mutations = {
  SET_TRIGGER(state, payload) {
    state.trigger = payload;
    console.log(state.trigger)
  },
  SET_ROWS(state, payload) {
    state.rows = payload;
    console.log(state.rows)
  },
};

const actions = {};

const getters = {
  trigger: (state) => state.trigger,
  rows: (state) => state.rows,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
