import store from "@/store/index.js";

export default function (err_code, additional = "object") {
  let result = `Ошибка №${err_code}. Обратитесь в поддержку.`;

  let errorMessages = {
    4000: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4001: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4002: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4003: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4004: `Ошибка ${err_code}. Попробуйте другой email.`,
    4005: `Ошибка ${err_code}. Создание заявки временно недоступно.`,
    4006: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4007: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4008: `Ошибка ${err_code}. Сумма не соответсвует лимитам платежного метода.`,
    4009: `Ошибка ${err_code}. Сумма не соответсвует лимитам платежного метода.`,

    40010: `Ошибка ${err_code}. У Вас уже есть созданная заявка.`,
    40011: `Ошибка ${err_code}. Необходимо подтвердить Email.`,
    40012: `Ошибка ${err_code}. Некорректные реквизиты.`,
    40013: `Ошибка ${err_code}. На балансе недостаточно средств.`,
    40014: `Ошибка ${err_code}. Перед выводом Вам необходимо сделать ставки в играх на сумму ${store.state.auth.user?.site_user?.withdraw_limit} ${store.getters["auth/user_currency_name"]})`,
    40015: `Ошибка ${err_code}. Необходимо выбрать валюту.`,
    40016: `Ошибка ${err_code}. Вы достигли суточного лимита на вывод. Максимальная сумма доступная сейчас - ${store.state.auth.user?.site_user?.withdraw_limit} ${store.getters["auth/user_currency_name"]}}.`,
    40017: `Ошибка ${err_code}. Невозможно выполнить это действие.`,
    40018: `Ошибка ${err_code}. Превышен суточный лимит.`,
    40019: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    40020: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40021: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40022: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40023: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40024: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40025: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40026: `Ошибка ${err_code}. Средств на Вашем счете недостаточно.`,
    40027: `Ошибка ${err_code}. У Вас уже есть купленный лотерейный билет.`,
    40028: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40029: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    40030: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40031: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40032: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40033: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40034: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40035: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40036: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    40043: `Ошибка ${err_code}. У Вас уже есть активный бонус. Для продолжения этого действия Вам необходимо отменить текущий бонус или отыграть его.`,
    40044: `Ошибка ${err_code}. У Вас уже есть активный бонус. Для продолжения этого действия Вам необходимо отменить текущий бонус или отыграть его.`,
    40045: `Ошибка ${err_code}. Количество активаций этого промокода закончилось.`,
    40046: `Ошибка ${err_code}. Количество активаций этого промокода закончилось .`,
    40047: `Ошибка ${err_code}. Количество активаций этого промокода закончилось .`,
    40048: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    40050: `Ошибка ${err_code}. Количество активаций этого промокода закончилось .`,
    40051: `Ошибка ${err_code}. Количество активаций этого промокода закончилось .`,
    40052: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40053: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40054: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40055: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40056: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40057: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40058: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40059: `Ошибка ${err_code}. Попробуйте другой email.`,

    40060: `Ошибка ${err_code}. Username уже используется.`,
    40061: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40062: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40063: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40064: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40065: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40066: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40067: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40068: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40069: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    40070: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40071: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    40072: `Ошибка ${err_code}. Вывод средств временно недоступен.`,
    40073: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    4010: `Ошибка ${err_code}. Неверные данные авторизации.`,
    4011: `Ошибка ${err_code}. Неверный otp пароль.`,
    4012: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,
    4013: `Ошибка ${err_code}. Пожалуйста, обратитесь в поддержку.`,

    42901: `Ошибка ${err_code}. Попробуйте позже.`,

    // legacy
    40: "Введенная сумма меньше допустимой.",
    41: "Введенная сумма больше допустимой.",
    42: "Введите корректные реквизиты.",
    43: "У Вас уже есть активная заявка на депозит.",
    44: "У Вас уже есть активная заявка на вывод.",
    45: "У Вас недостаточно средств.",
    46: `Вам требуется сделать сделать ставки в играх на сумму ${store.state.auth.user?.site_user?.withdraw_limit} перед выводом.`,
    47: "Данный платежный метод недоступен для Вашей валюты.",
    48: "Данный платежный метод недоступен. Выберите валюту.",
    49: "Превышен дневной лимит вывода средств для Вашего статуса.",
    401: "Способ оплаты недоступен.",
    402: `У Вас уже есть активный ${additional} .`,
    403: "У Вас нет купленного билета.",
    404: "Вы не открыли все ячейки.",
    405: "У Вас уже есть один активный бонус.",
    31: "Вы уже подтвердили свой email.",
    32: "Введите пароль.",
    33: "Вы ввели неверные данные.",
    34: "Вы ввели неверные данные.",
    35: "Доступ для IP закрыт.",
    36: "У Вас недостаточно прав, чтобы выполнить это действие.",
    37: "Доступ запрещен",
    20: `${additional} не существует.`,
    21: `${additional}  не активен.`,
    22: "Этот режим недоступен для игры.",
    23: "Ошибка валидации.",
    24: "Номер поля вне допустимого диапазона.",
    25: "У Вас есть активный бонус.",
    26: `${additional} уже была использована.`,
    27: "Превышен лимит активаций.",
    28: "Неверные данные",
    29: "Неправильный ключ.",
    201: "Вы не выполнили условия розыгрыша.",
    202: "Игра не поддерживает freespins",
    203: "В целях безопасности вывод средств запрещен в течении 24 часов после смены пароля.",
    10: "Ошибка с запросом API мерчанта",
  };

  for (const [key, value] of Object.entries(errorMessages)) {
    if (key == err_code) result = value;
  }
  return result;
}
