<template>
  <button class="button--icon" :class="{ active: active }" type="button">
    <Icon :name="icon" :class="`h-[${iconSize}] w-[${iconSize}]`" />
  </button>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  props: {
    icon: String,
    iconSize: {
      default: '24px', 
      type: String,
    },
    active: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Icon: defineAsyncComponent(() => import("../Icon.vue")),
  },
};
</script>

<style scoped lang="scss">
.button--icon {
  @apply flex-[0_0_auto] flex items-center justify-center h-[40px] w-[40px]  rounded-[40px] duration-[0.2s] opacity-80;

  & > svg {
    @apply stroke-color-text-main fill-color-text-main;
  }

  &:hover {
    @apply opacity-100 bg-icons/20;
  }

  &.active {
    & > svg {
      @apply stroke-main fill-main;
    }
  }
}
</style>
