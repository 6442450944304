import { createStore, createLogger } from "vuex";
import warnings from "global-plugins/store/warnings";
import tables from "global-plugins/store/tables";
import auth from "./modules/auth";
import currencies from "./modules/currencies";
import pages from "./modules/pages";
import withdraws from "./modules/withdraws";
import popups from "global-plugins/store/popups";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    warnings,
    tables,
    auth,
    currencies,
    pages,
    popups,
    withdraws,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
