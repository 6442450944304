const state = () => ({
  warnings: [],
});

const mutations = {
  CREATE_WARNING(state, payload) {
    state.warnings.push({
      title: payload.title || null,
      text: payload.text || null,
      status: payload.status || "system",
    });
  },
  CLEAR_WARNINGS(state, payload) {
    state.warnings = [];
  },
};

const actions = {};

const getters = {
  warnings: (state) => state.warnings,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
