<template>
  <div class="relative w-full">
    <!-- Placeholder-->
    <div class="flex justify-between mb-[4px]">
      <p v-if="title" class="text-main-1">{{ title }}</p>
      <div class="text-main-1">
        <slot name="header" />
      </div>
    </div>

    <!-- Input container -->
    <div
      class="input--rounded--container disable-click-outside cursor-pointer h-[48px]"
      :class="{ active: menuIsShown, error: error }"
      @click="menuIsShown = !menuIsShown"
      v-click-outside="closeMenu"
    >
      <!-- selected item -->
      <div v-if="value" class="menu--item menu--placeholder">
        <img
          v-if="activeEl.icon"
          :src="`/frontend/images/select/communication/${activeEl.icon}.svg`"
        />
        <p>{{ activeEl.text }}</p>
      </div>
      <p v-else class="placeholder">{{ placeholder }}</p>

      <!-- "show/hide" button -->
      <ButtonIconRounded
        class="mx-[4px]"
        :class="{ 'rotate-180': menuIsShown }"
        :active="menuIsShown"
        :icon="'arrow-select'"
      />
    </div>

    <!-- menu -->
    <transition name="transition-fade">
      <div v-if="menuIsShown" class="menu disable-click-outside">
        <div
          v-for="(input, index) in elements"
          :key="input.key"
          @click="changeValue(input.key)"
          class="menu--item"
        >
          <img
            v-if="input.icon"
            :src="`/frontend/images/select/communication/${input.icon}.svg`"
          />
          <p>{{ input.text }}</p>
          <div
            v-if="input.commission || input.commission == 0"
            class="flex items-center justify-center ml-auto px-[8px] py-[5px] rounded-[9999px] bg-icons/20"
          >
            <p class="text-sm text-color-text-secondary-2 leading-[10px]">
              Комиссия:
              <span class="text-color-text-main">{{ input.commission }}%</span>
            </p>
          </div>
        </div>
      </div>
    </transition>

    <!-- Error text -->
    <p v-if="error" class="mt-[8px] text-status-red text-sm-1">{{ error }}</p>
  </div>
</template>

<script lang="js">
import ButtonIconRounded from "../button/iconRounded.vue"
import Icon from "../Icon.vue"

export default {
  components: {
    ButtonIconRounded,
    Icon,
  },
  props: {
    items: Array,
    value: {
      default: null,
      type: String
    },
    placeholder: {
      default: null,
      type: String
    },
    title: {
      default: "Выбрать",
      type: String
    },
  },
  emits: ["update:value"],
  data() {
    return {
      focused: false,
      error: null,
      password: this.hide,
      menuIsShown: false,
      elements: this.items,
    }
  },
  computed: {
    activeEl() {
      if(this.value) {
        return this.elements.find((el) => el.key === this.value)
      } else return null
    }
  },
  methods: {
    setError(data) {
      this.error = data;
    },
    clear() {
      this.$emit("update:value", null)
      this.error = null;
      this.$refs.input.focus();
    },
    setItems(data) {
      this.elements = data;
    },
    closeMenu(event) {
      if (this.filterClickOutside(event)) {
        this.menuIsShown = false;
      }
    },
    changeValue(event) {
      this.$emit('update:value', event)
      this.menuIsShown = false
      this.setError(null)
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  @apply absolute w-full p-[5px] bg-white rounded-[8px] z-[2] max-h-[40vh] overflow-auto;
  top: calc(100% + 8px);

  box-shadow: 0px 0px 35px rgba(109, 120, 150, 0.15);

  &--item {
    @apply w-full h-[40px] flex items-center gap-[10px] p-[10px] w-full h-full rounded-[5px] text-main-1;

    & > svg {
      @apply w-[20px] aspect-square;
    }

    &:not(.menu--placeholder) {
      @apply cursor-pointer hover:bg-zebra active:bg-back;
    }
  }
}

input[type="radio"] {
  display: none;
}
</style>
