import axios from "axios";
import store from "@/store/index";
import { default as getErrorMessage } from "./error-codes.js";

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
const http = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === "development" ? "https://test.sgames.pro/api" : "/api",
});

function errorResponseHandler(err) {
  console.log(err);
  if (err.response?.data?.error_code) {
    console.log(err.response?.data?.error_code)
    switch (err.response.status) {
      case 403:
        break;
      default:
        store.commit("warnings/CREATE_WARNING", {
          title: `Ошибка ${err.response?.data?.error_code}`,
          text: `${getErrorMessage(err.response?.data?.error_code)}`,
          status: "error",
        });
        break;
    }
  }
  return Promise.reject(err.response?.data?.error_code);
}

http.interceptors.response.use((response) => response, errorResponseHandler);

export default http;
