<template>
  <div class="input--lg">
    <div>
      <!-- Input component -->
      <input
        :readonly="readonly"
        ref="input"
        :class="{ active, wrong }"
        :value="value"
        :type="numbers ? 'number' : type"
        :inputmode="numbers ? 'numeric' : 'text'"
        min="0"
        placeholder=""
        @keydown.enter="$emit('enter')"
        @input="$emit('update:value', $event.target.value)"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />

      <!-- Eye icons -->
      <div v-if="password" class="input--lg--icons">
        <Icon
          v-if="type == 'password'"
          :name="'eye-closed'"
          @click="type = 'text'"
        />
        <Icon
          v-if="type == 'text'"
          :name="'eye-opened'"
          @click="type = 'password'"
        />
      </div>

      <!-- Placeholder -->
      <span class="floating-label">
        {{ placeholder }}
      </span>

      <div class="pt-[7px]">
        <slot />
      </div>
    </div>

    <!-- Warning text -->
    <p v-if="wrong" class="text-sm text-status-red mt-[5px]">
      {{ warningText }}
    </p>
  </div>
</template>

<script>
import Icon from "../Icon.vue";

export default {
  components: { Icon },
  props: {
    value: String,
    readonly: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    warningText: {
      default: "Неверные данные",
      type: String,
    },
    placeholder: {
      default: "Введите",
      type: String,
    },
    numbers: {
      default: false,
      type: Boolean,
    },
    input_type: {
      default: "text",
      type: String,
    },
  },
  emits: ["update:value", "enter"],
  data() {
    return {
      wrong: false,
      type: false,
      password: null,
      isHovered: false,
      isFocused: false,
    };
  },
  computed: {
    content() {
      return this.value;
    },
    active() {
      return (
        this.isHovered ||
        this.isFocused ||
        ((this.value || this.value == 0) && this.value !== "")
      );
    },
  },
  methods: {
    changeStatus(state) {
      this.wrong = state;
    },
    focusInput() {
      this.$refs.input.focus();
    },
  },
  mounted() {
    this.type = this.hide ? "password" : "text";
    if (this.hide) {
      this.password = true;
      this.type = "password";
    } else {
      this.type = this.input_type;
    }
  },
};
</script>

<style scoped lang="scss"></style>
