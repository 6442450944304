<template>
  <header class="header">
    <!-- logo -->
    <img src="/partner_app/logo/header.svg" alt="Slot" class="header--logo" />

    <!-- menu -->
    <div class="header--menu">
      <!-- balance -->
      <div class="header--menu--balance">
        <Icon :name="'wallet-filled'" class="header--menu--balance--icon" />
        <p class="header--menu--balance--text">
          {{ ref_user?.ref_balance }} ₽
        </p>
      </div>

      <!-- profile button -->
      <button
        class="relative flex items-center gap-[8px] h-3 rounded-[16px]"
        @click="showProfile = !showProfile"
      >
        <Avatar gradient class="h-full" />
        <div
          class="flex items-center justify-center h-2 aspect-square duration-0"
          :class="{ 'rotate-180': showProfile }"
        >
          <Icon :name="'arrow-select'" class="fill-color-text-main" />
        </div>
      </button>

      <!-- profile menu -->
      <HeaderProfile
        v-if="showProfile"
        class="absolute top-[calc(100%_-_5px)] right-[16px]"
        v-click-outside="() => (showProfile = false)"
      />
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { Icon } from "ui";
import Avatar from "@/components/Avatar.vue";
import HeaderProfile from "@/components/HeaderProfile.vue";

export default {
  components: { Icon, Avatar, HeaderProfile },
  data() {
    return {
      showProfile: false,
    };
  },
  computed: {
    ...mapGetters({ ref_user: "auth/ref_user" }),
  }
};
</script>

<style scoped lang="scss">
.header {
  @apply relative flex items-center justify-between h-[65px] px-[16px] bg-white border-b-[1px] border-solid border-inactive;

  &--logo {
    @apply w-[80px];
  }

  &--menu {
    @apply flex items-center gap-[20px];

    &--balance {
      @apply flex items-center gap-[16px] h-3 p-[8px] pr-[12px] bg-back rounded-[8px];

      &--icon {
        @apply fill-color-text-main w-[16px];
      }

      &--text {
        @apply text-button;
      }
    }
  }
}
</style>
