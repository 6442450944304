<template>
  <div
    class="flex items-center justify-start gap-[8px] text-button cursor-pointer leading-[1em]"
    :class="{ button }"
    @click="check"
  >
    <button class="checkbox" :class="{ active: value }">
      <Icon v-if="value" :name="'check'" />
    </button>
    <slot />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    Icon: defineAsyncComponent(() => import("./Icon.vue")),
  },
  props: {
    button: {
      default: false,
      type: Boolean,
    },
    value: {
      default: false,
      type: Boolean,
    },
    clickable: {
      default: true,
      type: Boolean,
    },
  },
  emits: ["update:value"],
  methods: {
    check() {
      if (this.clickable) this.$emit("update:value", !this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox {
  @apply flex-[0_0_auto] flex items-center justify-center w-[14px] aspect-square border-solid border-[2px] rounded-[3px] dark:border-[0px];

  & > svg,
  & > img {
    @apply fill-white stroke-white w-full;
  }

  &.active {
    @apply bg-color-text-main border-color-text-main hover:bg-color-text-main dark:bg-main;
  }

  &:not(.active) {
    @apply border-inactive dark:bg-second-dark hover:bg-back dark:hover:bg-main;
  }
}

.button {
  @apply shadow-lg p-[10px] rounded-[4px];
}
</style>
