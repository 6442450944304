<template>
  <div
    class="relative flex flex-col gap-[20px] h-full w-[200px] bg-back border-r-[1px] border-solid border-inactive duration-[0s] dark:bg-back-dark dark:border-main-dark dark:text-white"
    :class="{ 'w-[60px]': collapsed }"
    v-click-outside="hideOnMobile"
  >
    <template v-if="logo">
      <!-- Logo Lg -->
      <img
        v-if="!collapsed"
        class="m-[20px] dark:hidden"
        src="#"
        alt="SlotGames"
      />
      <img
        v-if="!collapsed"
        class="hidden m-[20px] dark:block"
        src="#"
        alt="SlotGames"
      />
      <!-- Logo Sm -->
      <img
        v-if="collapsed"
        class="w-[30px] m-[20px] mx-[15px] mb-[50px] dark:hidden"
        src="#"
        alt="SlotGames"
      />
      <img
        v-if="collapsed"
        class="hidden w-[30px] m-[20px] mx-[15px] mb-[50px] dark:block"
        src="#"
        alt="SlotGames"
      />
    </template>

    <!-- Links -->
    <nav class="mt-[30px] ml-[10px]">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        :to="link.to"
        class="relative aside--link"
        @mouseenter="hoveredLink = index"
        @mouseleave="hoveredLink = null"
      >
        <img
          class="flex-[0_0_auto] h-[20px] w-[20px]"
          :src="link.icon"
          alt=""
        />
        <p v-if="!collapsed" class="text-button">
          {{ link.text }}
        </p>

        <!-- Hover badge -->
        <BadgeAside
          v-if="index == hoveredLink && collapsed"
          class="absolute left-[40px] z-[20] tablet:hidden"
        >
          <p
            class="text-white text-[12px] heading-[16.8px] font-normal whitespace-nowrap"
          >
            {{ link.text }}
          </p>
        </BadgeAside>
      </router-link>
    </nav>

    <!-- Button "Скрыть" -->
    <button
      class="aside--link absolute bottom-[10px] left-[10px] right-[0] hover:text-color-main hover:fill-color-main active:text-color-text-main active:fill-color-text-main"
      @mouseenter="hoveredCollapse = true"
      @mouseleave="hoveredCollapse = false"
      @click="switchCollpase"
    >
      <div class="w-[26px]" :class="{ 'rotate-180': collapsed }">
        <Icon
          :name="'arrow-left-small'"
          class="flex-[0_0_auto] w-[26px] origin-left"
        />
      </div>

      <p v-if="!collapsed" class="text-button">Свернуть</p>

      <BadgeAside
        v-if="hoveredCollapse && collapsed"
        class="absolute left-[40px] z-[20] tablet:hidden"
      >
        <p
          class="text-white text-[12px] heading-[16.8px] font-normal whitespace-nowrap"
        >
          Развернуть
        </p>
      </BadgeAside>
    </button>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import BadgeAside from "./badge/aside.vue";

export default {
  components: { Icon, BadgeAside },
  props: {
    links: Array,
    logo: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      collapsed: false,
      hoveredLink: null,
      hoveredCollapse: null,
    };
  },
  methods: {
    switchCollpase() {
      this.collapsed = !this.collapsed;
      localStorage.setItem("asideCollapsed", this.collapsed);
    },
    hideOnMobile() {
      if (window.screen.width <= 900) {
        this.collapsed = true;
      }
    },
  },
  mounted() {
    if (
      localStorage.getItem("asideCollapsed") == "true" ||
      window.innerWidth <= 900
    ) {
      this.collapsed = true;
    }
  },
  watch: {
    $route(to, from) {
      this.hideOnMobile()
    },
  },
};
</script>

<style scoped lang="scss">
.aside--link {
  @apply flex items-center gap-[10px] p-[10px] rounded-l-[5px] hover:no-underline hover:bg-inactive active:bg-transparent dark:fill-white dark:hover:bg-main-dark dark:active:bg-transparent;
}

a.router-link-active {
  @apply bg-inactive cursor-default hover:bg-inactive active:bg-inactive dark:bg-main-dark;
}
</style>
