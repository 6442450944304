export default function (event) {
  if (
    event.srcElement.closest(".disable-click-outside") ||
    event.srcElement.closest(".input--lg") ||
    event.srcElement.closest("button") ||
    event.srcElement.closest("input") ||
    event.srcElement.closest("label") ||
    event.srcElement.closest("table") ||
    event.srcElement.closest("textarea") ||
    event.srcElement.closest("th") ||
    event.srcElement.closest("td") ||
    event.srcElement.closest("tr")
  ) {
    return false
  } else return true
}