<template>
  <div
    class="AuthLayout flex flex-col gap-[40px] items-center w-[100vw] h-[100vh] overflow-auto p-[70px] tablet:p-[10px] tablet:gap-[30px] mobile:p-[0px] tablet:absolute tablet:w-full tablet:h-full"
    :style="{ backgroundImage: `url('/partner_app/images/auth-banner.png')` }"
  >
    <img
      src="/partner_app/logo/lg-dark.svg"
      alt="Slot partner"
      class="mobile:absolute mobile:top-[20px]"
    />
    <router-view v-slot="{ Component }">
      <!-- <transition name="auth-fade" mode="out-in"> -->
      <component :is="Component" />
      <!-- </transition> -->
    </router-view>
  </div>
</template>

<script>

export default {
};
</script>

<style scoped lang="scss">
.AuthLayout {
  background: #0f1433;
  background-position: left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
</style>
