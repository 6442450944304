<template>
  <div class="LayoutDefault fixed grid h-[100%] w-[100%]">
    <Header class="area-header" />
    <div class="area-aside relative h-full bg-back">
      <div
        class="relative h-full w-full tablet:absolute tablet:inset-[0] tablet:z-[10]"
      >
        <Sidebar class="" :links="sidebarLinks" :logo="false" />
      </div>
      <div class="hidden tablet:block w-[60px] h-full"></div>
    </div>
    <main
      ref="Main"
      class="area-content relative h-full w-full px-[40px] py-[20px] bg-zebra overflow-auto tablet:p-[20px] mobile:p-[10px]"
    >
      <router-view />
    </main>
  </div>
</template>

<script>
import { Sidebar } from "ui";
import Header from "@/components/Header.vue";

export default {
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      sidebarLinks: [
        {
          icon: "/partner_app/images/sidebar/dashboard.svg",
          text: "Дашборд",
          to: "/dashboard",
        },
        {
          icon: "/partner_app/images/sidebar/fincances.svg",
          text: "Финансы",
          to: "/finances",
        },
        {
          icon: "/partner_app/images/sidebar/statistics.svg",
          text: "Статистика",
          to: "/statistics",
        },
        {
          icon: "/partner_app/images/sidebar/referals.svg",
          text: "Рефералы",
          to: "/referals",
        },
        {
          icon: "/partner_app/images/sidebar/link.svg",
          text: "Партнёрская ссылка",
          to: "/referal-link",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      // Scroll page to top
      if (this.$refs.Main) {
        this.$refs.Main.scrollTop = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.LayoutDefault {
  grid-template:
    "header header" auto
    "aside content" 1fr
    / auto 1fr;
}

.area-header {
  grid-area: header;
}
.area-aside {
  grid-area: aside;
}
.area-content {
  grid-area: content;
}
</style>
