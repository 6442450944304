<template>
  <div
    ref="aside"
    v-show="showAside"
    class="table--aside"
    id="table__aside"
    v-click-outside="clickOutside"
  >
    <!-- Aside header -->
    <div class="table--aside--header">
      <!-- Aside title -->
      <div class="table--aside--header__title" @click="closeAside()">
        <Icon name="arrow-left" class="w-[20px]" />
        <div id="table__aside__header">
          <slot name="title" />
        </div>
      </div>

      <!-- Aside menu -->
      <div class="table--aside--header__menu" id="table__aside__menu">
        <slot name="menu" />
      </div>
    </div>

    <!-- Aside content -->
    <div class="table--aside--content" id="table__aside__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Icon from "../Icon.vue";

export default {
  components: {
    Icon,
  },
  computed: {
    ...mapGetters({
      showAside: "pages/showAside",
    }),
  },
  methods: {
    ...mapMutations({ setAside: "pages/SET_ASIDE" }),
    closeAside() {
      this.setAside(false);
    },
    clickOutside(event) {
      if (this.filterClickOutside(event) && this.showAside) {
        this.closeAside();
      }
    },
  },
  watch: {
    showAside(newValue) {
      if (newValue) disableBodyScroll(this.$refs.aside);
    },
  },
};
</script>

<style lang="scss">
.table {
  &--aside {
    @apply relative flex-[0_0_auto] grid grid-cols-1 grid-rows-[auto_1fr] gap-[15px] h-full w-[400px] p-[15px] pb-[0] bg-white overflow-auto z-[10] dark:bg-main-dark;
    box-shadow: -7px 0px 65px rgba(109, 120, 150, 0.1);

    &--header {
      @apply flex justify-between items-center gap-[10px] w-full;

      &__title {
        @apply flex items-center gap-[10px] text-md-1 fill-color-text-main cursor-pointer dark:fill-white dark:text-white;
      }

      &__title svg {
        @apply w-[20px] duration-[0.3s];
      }

      &__title:hover svg {
        @apply rotate-180;
      }

      &__menu {
        @apply flex gap-[5px];
      }
    }

    &--content {
      @apply flex flex-col gap-[15px] pb-[15px];

      &__inputs {
        @apply flex flex-col gap-[15px];
      }
    }
  }
}
</style>
