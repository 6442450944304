<template>
  <div
    v-if="showComponent"
    class="loading-page-animation absolute inset-0 bg-black z-[200]"
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    hide: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      pageLoading: "pages/pageLoading",
      hideLoad: "pages/hideLoad",
    }),
    showComponent() {
      if (!this.hide) {
        return this.pageLoading && !this.hideLoad ? true : false;
      } else {
        return this.pageLoading && this.hideLoad ? true : false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loading-page-animation {
  animation-name: loading-page-animation;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes loading-page-animation {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
