<template>
  <div id="app">
    <!-- laoding page if not logged in -->
    <div
      v-if="$route.meta?.layout !== 'LayoutAuth' && !isLoggedIn"
      class="EmptyPage"
      :style="{ backgroundImage: `url('/partner_app/images/auth-banner.png')` }"
    >
      <img
        src="/partner_app/logo/lg-dark.svg"
        alt="Slot partner"
        class="absolute top-[70px] mobile:top-[20px]"
      />
    </div>

    <!-- layouts -->
    <component v-else :is="layout" class="z-[0]" />

    <transition name="transition-fade">
      <PopupsWindow class="z-[100]" />
    </transition>

    <ContainerWarnings class="fixed top-[85px] right-[20px] z-[200]" />
  </div>

  <!-- Change page animation -->
  <PageLoadingComponent class="fixed" />
</template>

<script>
import { onMounted } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PageLoading as PageLoadingComponent, ContainerWarnings } from "ui";
import { useRouter, useRoute } from "vue-router";
import { axios } from "global-plugins";
import LayoutDefault from "@/layouts/default.vue";
import LayoutAuth from "@/layouts/auth.vue";
import PopupsWindow from "@/components/PopupsWindow.vue";

export default {
  components: {
    PageLoadingComponent,
    ContainerWarnings,
    LayoutDefault,
    LayoutAuth,
    PopupsWindow,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    // open websocket
    let connection = new WebSocket(
      `wss://${window.location.host}/ws/notifications/`
    );
    connection.onmessage = function (message) {
      let messageData = JSON.parse(message.data);
      switch (messageData.event) {
        case "user":
          this.setUser(messageData.data);
          break;
        default:
          console.log("Unknown websocket event");
      }
    };

    onMounted(async () => {
      // login via telegram
      let auth_token = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      }).auth_token;
      await router.isReady();
      if (auth_token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${auth_token}`;
        await axios.post("/users/login_with_token/").then(() => {
          router.push("/dashboard");
        });
      }
    });
  },
  data: () => ({
    layout: "LayoutDefault",
  }),
  computed: {
    ...mapGetters({
      pageLoading: "pages/pageLoading",
      hideLoad: "pages/hideLoad",
      isLoggedIn: "auth/isLoggedIn",
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "auth/SET_USER",
      createWarning: "warnings/CREATE_WARNING",
    }),
    ...mapActions({
      get_me: "auth/get_me",
      loadNotifications: "notifications/load_notifications",
      login_with_token: "auth/login_with_token",
    }),
  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          this.layout = (await route.meta.layout) || "LayoutDefault";
        } catch (e) {
          this.layout = "LayoutDefault";
          console.log("Layout error!");
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "ui/css/main.scss";

.EmptyPage {
  @apply fixed inset-0 flex items-center justify-center z-[0];

  background: #0f1433;
  background-position: left center;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

#app {
  @apply text-color-text-main;

  input {
    @apply bg-transparent outline-[0px];
  }
}

.page-fade-enter-active,
.page-fade-leave-active {
  transition: opacity 0.3s ease;
}

.page-fade-enter-from,
.page-fade-leave-to {
  opacity: 0;
}
</style>
