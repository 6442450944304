<template>
  <div class="input--lg">
    <div class="cursor-pointer" @click="showDatePicker = true">
      <!-- Placeholder -->
      <span class="floating-label" :class="{ active: value }">
        {{ placeholder }}
      </span>

      <p class="input--lg--text">
        {{ value !== null ? value.text : null }}
      </p>

      <div class="input--lg--icons">
        <Icon :name="'date'" @click="showDatePicker = true" />
      </div>
      <VueDatepicker
        v-if="showDatePicker"
        class="absolute right-0 top-[calc(100%_+_10px)] flex-[0_0_auto] z-[10]"
        v-click-outside="() => (showDatePicker = false)"
        :time="time"
        :pickRange="pickRange"
        @update="setDate"
      />

      <slot />
    </div>

    <!-- Warning text -->
    <p v-if="wrong" class="text-sm text-status-red mt-[5px]">{{ warningText }}</p>
  </div>
</template>

<script>
import VueDatepicker from "../VueDatepicker.vue";
import ButtonSm from "../button/sm.vue";
import Icon from "../Icon.vue";

export default {
  components: { VueDatepicker, ButtonSm, Icon },
  props: {
    warningText: {
      default: "Неверные данные",
      type: String,
    },
    placeholder: {
      default: "Введите",
      type: String,
    },
    time: {
      default: false,
      type: Boolean,
    },
    pickRange: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      wrong: false,
      showDatePicker: false,
      value: null,
    };
  },
  computed: {
    content() {
      return this.value;
    },
  },
  methods: {
    changeValue(data) {
      this.value = data
    },
    setDate(data) {
      this.value = data;
      this.showDatePicker = false;
      this.$emit("update", this.value);
    },
  },
};
</script>

<style scoped lang="scss"></style>
