<template>
  <div
    class="relative min-w-[145px] h-[26px] select-none disable-click-outside"
    v-click-outside="closeMenu"
  >
    <!-- Header button -->
    <div
      class="relative flex items-center justify-between gap-[10px] w-full h-full pl-[10px] pr-[8px] bg-white rounded-[5px] fill-color-text-main cursor-pointer duration-[0s] z-[1] dark:bg-main-dark"
      :class="{
        'rounded-b-[0]': menuIsShown,
      }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      @click="menuIsShown = !menuIsShown"
    >
      <p class="text-button whitespace-nowrap">{{ title }}</p>

      <div
        class="flex-[0_0_auto] relative w-[10px] -rotate-90 dark:fill-white"
        :class="{ 'top-0': !hovered, 'top-[2px]': hovered && !menuIsShown }"
      >
        <Icon :name="'arrow-left'" />
      </div>
    </div>

    <!-- Menu -->
    <div
      v-if="menuIsShown"
      :class="{ shadows: menuIsShown }"
      class="absolute top-[100%] w-full bg-white rounded-b-[5px] overflow-hidden z-[0] dark:bg-main-dark"
    >
      <!-- Fast buttons -->
      <div class="flex flex-wrap gap-[4px] px-[8px] mb-[10px]">
        <BadgeTransparent @click="setAll(true)"> Выбрать все </BadgeTransparent>
        <BadgeTransparent @click="setAll(false)"> Сбросить </BadgeTransparent>
      </div>

      <!-- Menu list -->
      <div class="w-full max-h-[120px] overflow-y-auto cursor-pointer">
        <!-- Menu item -->
        <div
          v-for="(el, index) in elementsData"
          :key="index"
          class="flex items-center px-[10px] gap-[10px] w-full py-[4px]"
          @click="choose(index)"
        >
          <Checkbox v-model:value="el.checked" :clickable="false" />
          <p class="text-button">{{ el.text }}</p>
        </div>
      </div>

      <!-- Button -->
      <div
        class="w-full px-[10px] py-[10px] bg-white shadow-[0_0_20px_rgba(0,0,0,0.1)] dark:bg-main-dark"
      >
        <button
          class="flex items-center justify-center w-full py-[6.5px] bg-color-text-main rounded-[5px] text-button text-white hover:bg-main active:bg-color-text-main"
          @click="apply"
        >
          Применить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BadgeTransparent from "../badge/transparent.vue";
import Checkbox from "../Checkbox.vue";
import Icon from "../Icon.vue";

export default {
  components: { BadgeTransparent, Checkbox, Icon },
  props: {
    elements: Array,
    title: {
      default: "Настроить столбцы",
      type: String,
    },
  },
  data() {
    return {
      menuIsShown: false,
      hovered: false,
      elementsData: [],
    };
  },
  methods: {
    closeMenu(event) {
      if (this.filterClickOutside(event) && this.menuIsShown) {
        this.menuIsShown = false;
        this.$emit("closed", this.elementsData);
      }
    },
    choose(id) {
      this.elementsData[id].checked = !this.elementsData[id].checked;
      this.$emit("choose", this.elementsData);
    },
    apply(close) {
      this.$emit("apply", this.elementsData);
      if (close) this.menuIsShown = false;
    },
    setAll(status) {
      this.elementsData.forEach(el => el.checked = status)
      this.apply(false)
    }
  },
  mounted() {
    this.elementsData = this.elements;
  },
};
</script>

<style scoped lang="scss">
.shadows {
  box-shadow: 0px 0px 35px rgba(109, 120, 150, 0.15);
}
</style>
