<template>
  <div
    class="relative flex items-center justify-center aspect-square p-[2px] bg-avatar-blue rounded-full"
  >
    <p class="text-sm-1 text-white font-normal z-[2]">{{ letter }}</p>
    <div
      v-if="gradient"
      class="absolute inset-[0] bg-gradient-to-b from-white to-white/0 z-[1] opacity-25"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    gradient: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({ user: "auth/user" }),
    letter() {
      return this.user?.username[0].toUpperCase() || this.user?.email[0].toUpperCase() || "A";
    },
  },
};
</script>

<style scoped lang="scss"></style>
