<template>
  <div class="w-full">
    <!-- Placeholder-->
    <div class="flex justify-between mb-[4px]">
      <p v-if="title" class="text-main-1">{{ title }}</p>
      <div class="text-main-1">
        <slot name="header" />
      </div>
    </div>

    <!-- Input container -->
    <div
      class="input--rounded--container"
      :class="{ active: focused, error: error }"
    >
      <input
        ref="input"
        :placeholder="inputPlaceholder"
        :value="value"
        :type="hide && password ? 'password' : 'text'"
        @keydown.enter="$emit('enter')"
        @input="input($event)"
        @focus="focused = true"
        @blur="focused = false"
      />

      <!-- Buttons -->
      <div v-if="error || hide" class="flex items-center gap-[2px] pr-[4px]">
        <!-- "clear" button -->
        <button v-if="error" class="input--rounded--button" @click="clear">
          <Icon :name="'close'" class="w-[12px]" />
        </button>
        <!-- "hide/show" buttons -->
        <ButtonIconRounded
          v-if="hide"
          :icon="hide && password ? 'eye-closed' : 'eye-opened'"
          @click="password = !password"
        />
      </div>

      <slot name="menu" />
    </div>

    <!-- Error text -->
    <p v-if="error" class="mt-[8px] text-status-red text-sm-1">{{ error }}</p>
  </div>
</template>

<script lang="js">
import inputRegex from "../../../global-plugins/plugins/input-regexes"
import ButtonIconRounded from "../button/iconRounded.vue"
import Icon from "../Icon.vue"

export default {
  components: {
    ButtonIconRounded,
    Icon,
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    regexp: {
      type: null,
      default: null
    }
  },
  emits: ["update:value", "enter"],
  data() {
    return {
      focused: false,
      error: null,
      password: this.hide,
    }
  },
  computed: {
    inputPlaceholder() {
      return this.regexp ? inputRegex("", new RegExp(this.regexp)).placeholder : this.placeholder
    }
  },
  methods: {
    input(event) {
      let regexResult = this.regexp ? inputRegex(
        event.target.value,
        new RegExp(this.regexp)).text : event.target.value

      this.$refs.input.value = regexResult
      this.$emit('update:value', regexResult)

      console.log(this.regexp)
      console.log(regexResult)
    },
    setError(data) {
      this.error = data;
    },
    clear() {
      this.$emit("update:value", null)
      this.error = null;
      this.$refs.input.focus();
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (!newValue || !oldValue) this.setError(null)
    }
  }
}
</script>

<style scoped lang="scss"></style>
