<template>
  <button
    class="flex-[0_0_auto] relative flex items-center justify-center self-start gap-[8px] rounded-[5px] text-button cursor-pointer"
    :class="[{ disabled }, `color--${color}`]"
    :type="'submit' ? submit : 'button'"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    color: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    submit: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
button:not(.disabled).color {
  &--main {
    @apply bg-main text-white fill-white stroke-white hover:bg-status-blue active:bg-main dark:bg-second-dark dark:hover:bg-main dark:active:bg-second-dark dark:text-white dark:fill-white dark:stroke-white;
  }

  &--back {
    @apply bg-back text-color-text-main fill-color-text-main stroke-color-text-main hover:bg-inactive active:bg-back dark:bg-main-dark dark:hover:bg-second-dark dark:active:bg-main-dark dark:text-white dark:fill-white dark:stroke-white;
  }

  &--white {
    @apply bg-white text-color-text-main fill-color-text-main stroke-color-text-main hover:bg-back active:bg-white dark:bg-second-dark dark:hover:bg-main dark:active:bg-second-dark dark:text-white dark:fill-white dark:stroke-white;
  }

  &--transparent {
    @apply bg-transparent text-color-text-main fill-color-text-main stroke-color-text-main hover:bg-[rgba(0,0,0,0.1)] active:bg-[rgba(0,0,0,0.2)]  dark:hover:bg-[rgba(300,300,300,0.1)] dark:active:bg-[rgba(300,300,300,0.2)] dark:text-white dark:fill-white dark:stroke-white;
  }

&--transparent__main {
  @apply bg-transparent text-main fill-main stroke-main hover:bg-[rgba(0,0,0,0.05)] active:bg-[rgba(0,0,0,0.1)]  dark:hover:bg-[rgba(300,300,300,0.05)] dark:active:bg-[rgba(300,300,300,0.1)] dark:text-white dark:fill-white dark:stroke-white;
}
}

button.disabled {
  @apply bg-inactive cursor-default fill-color-text-secondary stroke-color-text-secondary text-color-text-secondary hover:bg-inactive active:bg-inactive dark:text-white dark:fill-white dark:stroke-white;
}
</style>
