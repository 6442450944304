<template>
  <div
    class="relative min-w-[145px] h-[26px] rounded-[5px] select-none disable-click-outside"
    v-click-outside="closeMenu"
  >
    <!-- Header button -->
    <div
      class="relative flex items-center justify-between gap-[10px] w-full h-full pl-[10px] pr-[8px] bg-white rounded-[5px] fill-color-text-main cursor-pointer duration-[0s] z-[1] dark:bg-main-dark"
      :class="{
        'rounded-b-[0]': menuIsShown,
        'bg-inactive dark:bg-main': choosedEl != null && !menuIsShown,
      }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
      @click="menuIsShown = !menuIsShown"
    >
      <p v-if="choosedEl == null" class="text-button whitespace-nowrap">{{ title }}</p>
      <p v-else class="text-button whitespace-nowrap">{{ elements[choosedEl] }}</p>

      <div
        v-if="choosedEl == null"
        class="relative w-[10px] -rotate-90 fill-color-text-main dark:fill-white"
        :class="{ 'top-0': !hovered, 'top-[2px]': hovered && !menuIsShown }"
      >
        <Icon :name="'arrow-left'" />
      </div>

      <button
        v-else
        class="flex items-center justify-center relative w-[8px] stroke-color-text-main z-[2] dark:stroke-white"
        @click="clearValue()"
      >
        <Icon :name="'close'" class="w-[8px]" />
      </button>
    </div>

    <!-- Menu -->
    <div
      v-if="menuIsShown"
      :class="{ shadows: menuIsShown }"
      class="absolute top-[100%] w-full bg-white rounded-b-[5px] overflow-hidden z-[0] dark:bg-main-dark"
    >
      <button
        v-for="(el, index) in elements"
        :key="index"
        class="flex items-center px-[10px] gap-[10px] w-full h-[26px] hover:bg-inactive active:bg-transparent dark:hover:bg-main dark:active:bg-transparent dark:active:text-main"
        :class="{
          'bg-inactive dark:bg-main': index === choosedEl,
        }"
        @click="chooseElement(index)"
      >
        <p class="text-button">{{ el }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon.vue";

export default {
  components: { Icon },
  props: {
    elements: Array,
    title: {
      default: "Выберите",
      type: String,
    },
  },
  data() {
    return {
      choosedEl: null,
      menuIsShown: false,
      hovered: false,
    };
  },
  methods: {
    chooseElement(index) {
      this.choosedEl = index;
      this.menuIsShown = false;
      this.$emit("choosed", {
        value: this.elements[this.choosedEl],
        index: this.choosedEl,
      });
    },
    clearValue() {
      this.choosedEl = null;
      this.menuIsShown = false;
      this.$emit("clear");
    },
    closeMenu(event) {
      if (this.filterClickOutside(event)) {
        this.menuIsShown = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.shadows {
  box-shadow: 0px 0px 35px rgba(109, 120, 150, 0.15);
}
</style>
