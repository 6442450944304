<template>
  <div
    v-if="!user.site_user?.is_partner"
    class="flex-[1_0_auto] flex flex-col items-center justify-center w-full py-[100px] px-[10px] bg-white text-center"
  >
    <p class="text-lg-1 mt-[15px]">Аккаунт на модерации</p>
    <p class="text-color-text-hover text-main-1 mt-[30px]">
      Наши менеджеры скоро свяжутся с вами <br class="mobile:hidden" />
      и предоставят доступ к
      <span class="whitespace-nowrap">Slot partner.</span> <br /><br />
      Остались вопросы? Пишите нам
    </p>
    <a
      href="https://t.me/Slotgames_Partners"
      target="_blank"
      rel="noopener noreferrer"
      class="mt-[30px]"
    >
      <ButtonMd> Связаться в Telegram </ButtonMd>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonMd from "@/components/button/md.vue";

export default {
  components: {
    ButtonMd,
  },
  computed: {
    ...mapGetters({ user: "auth/user" }),
  },
};
</script>

<style scoped lang="scss"></style>
