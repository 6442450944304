<template>
  <div class="flex-[0_0_auto] relative z-[2]">
    <ButtonSm
      class="bg-white active:bg-white"
      :color="buttonColor"
      @click="showPicker = !showPicker"
    >
      <Icon :name="'date'" />
      <p>{{ date?.text || "Выберите дату" }}</p>
    </ButtonSm>

    <VueDatepicker
      v-if="showPicker"
      class="absolute top-[calc(100%_+_10px)] z-[10]"
      :class="{ 'right-0': !rotate, 'left-0': rotate }"
      :time="time"
      v-model="dateForComponent"
      v-click-outside="hidePicker"
      @update="setDate"
    />
  </div>
</template>

<script>
import VueDatepicker from "./VueDatepicker.vue";
import Icon from "./Icon.vue";
import ButtonSm from "./button/sm.vue";

export default {
  components: { VueDatepicker, Icon, ButtonSm },
  props: {
    time: {
      default: false,
      type: Boolean,
    },
    buttonColor: {
      type: String,
      default: "white",
    },
    rotate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPicker: false,
      date: null,
    };
  },
  computed: {
    dateForComponent() {
      return this.date ? this.date.raw : null;
    },
  },
  methods: {
    hidePicker() {
      this.showPicker = false;
    },
    setDate(data) {
      this.date = data;
      this.hidePicker();
      console.log(data);
      this.$emit("update", data);
    },
  },
};
</script>

<style lang="scss"></style>
