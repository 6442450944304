<template>
  <div class="flex items-center">
    <!-- Prev -->
    <button
      class="change--page"
      :class="{
        active: choosedPage !== 0,
      }"
      @click="prev"
    >
      <div class="h-[12px] aspect-square">
        <Icon :name="'arrow-left'" />
      </div>
    </button>

    <!-- Pages -->
    <div class="flex gap-[5px]">
      <!-- Short length of pages -->
      <template v-if="pages <= 7">
        <ButtonSquare
          v-for="(page, index) in pages"
          :key="index"
          :active="index === choosedPage"
          @click="changePage(index)"
        >
          {{ page }}
        </ButtonSquare>
      </template>
      <!-- First pages -->
      <template v-else-if="choosedPage <= 3">
        <ButtonSquare
          v-for="(page, index) in 5"
          :key="index"
          :active="index === choosedPage"
          @click="changePage(index)"
        >
          {{ page }}
        </ButtonSquare>
        <ButtonSquare> ... </ButtonSquare>
        <ButtonSquare @click="changePage(pages - 1)">
          {{ pages }}
        </ButtonSquare>
      </template>
      <!-- Last pages -->
      <template v-else-if="choosedPage >= pages - 4">
        <ButtonSquare @click="changePage(0)"> 1 </ButtonSquare>
        <ButtonSquare> ... </ButtonSquare>
        <ButtonSquare
          v-for="(page, index) in [4, 3, 2, 1, 0]"
          :key="index"
          :active="pages - page - 1 === choosedPage"
          @click="changePage(pages - page - 1)"
        >
          {{ pages - page }}
        </ButtonSquare>
      </template>
      <!-- Middle pages -->
      <template v-else>
        <ButtonSquare @click="changePage(0)"> 1 </ButtonSquare>
        <ButtonSquare> ... </ButtonSquare>
        <ButtonSquare @click="changePage(choosedPage - 1)">
          {{ choosedPage }}
        </ButtonSquare>
        <ButtonSquare :active="true">
          {{ choosedPage + 1 }}
        </ButtonSquare>
        <ButtonSquare @click="changePage(choosedPage + 1)">
          {{ choosedPage + 2 }}
        </ButtonSquare>
        <ButtonSquare> ... </ButtonSquare>
        <ButtonSquare @click="changePage(pages - 1)">
          {{ pages }}
        </ButtonSquare>
      </template>
    </div>

    <!-- Next -->
    <button
      class="change--page"
      :class="{
        active: choosedPage !== pages - 1,
      }"
      @click="next"
    >
      <div class="h-[12px] aspect-square rotate-180">
        <Icon :name="'arrow-left'" />
      </div>
    </button>
  </div>
</template>

<script>
import ButtonSquare from "./button/square.vue";
import Icon from "./Icon.vue";

export default {
  components: { ButtonSquare, Icon },
  props: {
    pages: {
      default: 1,
      type: Number,
    },
  },
  data() {
    return {
      choosedPage: 0,
    };
  },
  methods: {
    next() {
      if (this.choosedPage !== this.pages - 1) {
        this.choosedPage++;
        this.$emit("changePage", this.choosedPage + 1);
      }
    },
    prev() {
      if (this.choosedPage !== 0) {
        this.choosedPage--;
        this.$emit("changePage", this.choosedPage + 1);
      }
    },
    changePage(page) {
      if (this.choosedPage !== page) {
        this.choosedPage = page;
        this.$emit("changePage", page + 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.change--page {
  @apply flex items-center justify-center w-[25px] aspect-square;

  &.active {
    @apply fill-color-text-main cursor-pointer dark:fill-white;
  }
  &:not(.active) {
    @apply fill-color-text-secondary cursor-default dark:fill-color-text-secondary;
  }
}
</style>
