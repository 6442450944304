import store from "@/store/index"

export default (app, options) => {
  const setPopup = function (name = null, data = null) {
    store.commit("popups/SET_POPUP", {
      name: name,
      data: data,
    })
  }

  app.config.globalProperties.$setPopup = setPopup
}