const state = () => ({
  popup: {
    name: null,
    data: null,
  }
})

const mutations = {
  SET_POPUP(state, payload) {
    state.popup = {
      name: payload.name || null,
      data: payload.data || null,
    }
  }
}

const actions = {
}

const getters = {
  popup: (state) => state.popup
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}