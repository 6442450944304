<template>
  <div class="w-full">
    <p v-if="placeholder" class="text-sm-1 mb-[10px]">{{ placeholder }}</p>
    <div class="flex flex-wrap gap-[8px] w-full">
      <BadgeGrey
        v-for="(item, index) in items"
        :key="index"
        :active="item.value === value"
        @click="$emit('update:value', item.value)"
      >
        {{ item.name }}
      </BadgeGrey>
    </div>
  </div>
</template>

<script>
import BadgeGrey from "./grey.vue";

export default {
  components: {
    BadgeGrey,
  },
  props: ["items", "value", "placeholder"],
  emits: ["update:value"],
};
</script>

<style scoped lang="scss"></style>
