const state = () => ({
  pageLoading: false,
  hideLoad: false,
});

const mutations = {
  SET_PAGE_LOADING(state, payload) {
    state.pageLoading = payload;
  },
  SET_HIDE_LOAD(state, payload) {
    state.hideLoad = payload;
  },
};

const actions = {};

const getters = {
  pageLoading: (state) => state.pageLoading,
  hideLoad: (state) => state.hideLoad,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
