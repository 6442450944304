<template>
  <thead class="table--header z-[5]">
    <tr class="table--header--row">
      <th v-if="checkbox !== null" class="checkboxCol">
        <Checkbox :active="checkbox" @click="$emit('checkbox')" />
      </th>
      <th
        v-for="(header, index) in elements"
        v-show="header.checked === true || header.checked === undefined"
        class="table--header--el"
        :key="index"
        :class="{ disabled: !header.value }"
      >
        <div class="flex flex-col gap-[5px] min-h-[30px]">
          <div
            class="table--header--el--title"
            :class="{
              'justify-end': header.right,
              'justify-center': header.center,
            }"
            @click="toggle(index)"
          >
            <p>{{ header.text }}</p>

            <div v-if="header.value" class="flex flex-col gap-[3px]">
              <Icon
                :name="'top-simple'"
                class="table--header--el__icon"
                :class="{
                  active:
                    activeElement !== index ||
                    (activeElement === index &&
                      (filterValue === 1 || filterValue === null)),
                }"
              />
              <Icon
                :name="'top-simple'"
                class="table--header--el__icon rotate-180"
                :class="{
                  active:
                    activeElement !== index ||
                    (activeElement === index &&
                      (filterValue === 2 || filterValue === null)),
                }"
              />
            </div>
          </div>

          <InputSearch
            v-if="header.component?.is == 'input'"
            :placeholder="'Поиск'"
            :button="false"
            :disabled="header.component?.disabled"
            class="min-w-[120px] max-w-[160px] border-[1px] border-solid border-inactive z-[5]"
            @enter="
              (data) =>
                $emit('setHeaderValue', {
                  id: index,
                  value: data,
                })
            "
          />
          <Dropdown
            v-if="header.component?.is == 'dropdown'"
            :name="'Все'"
            :elements="generateDropdown(header.component?.data)"
            class="border-[1px] border-solid border-inactive z-[5]"
            @choosed="
              (data) =>
                $emit('setHeaderValue', {
                  id: index,
                  value: header.component.data[data.index].value,
                })
            "
          />
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import InputSearch from "../input/search.vue";
import Dropdown from "../dropdown/radio.vue";
import Checkbox from "../Checkbox.vue";
import Icon from "../Icon.vue";

export default {
  components: {
    InputSearch,
    Dropdown,
    Checkbox,
    Icon,
  },
  props: {
    elements: Array,
    checkbox: {
      default: null,
      type: Boolean,
    },
  },
  data() {
    return {
      activeElement: null,
      filterValue: null,
    };
  },
  methods: {
    toggle(index) {
      if (this.elements[index].value) {
        if (this.activeElement === index) {
          this.filterValue =
            this.filterValue === null ? 1 : this.filterValue === 1 ? 2 : null;
        } else {
          this.filterValue = 1;
        }
        this.activeElement = index;
        this.$emit(
          "toggle",
          `${this.filterValue === 2 ? "-" : ""}${
            this.filterValue !== null ? this.elements[index].value : ""
          }`
        );
      }
    },
    generateDropdown(data) {
      let result = [];
      data.forEach((el) => {
        if (el.name !== undefined) {
          result.push(el.name);
        } else {
          result.push(el.text);
        }
      });
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.checkboxCol {
  @apply w-[22px] pl-[15px] pr-[0px];
}
</style>
